import { WIDGETS } from '../config';
import { AsyncStorageCollectionObjectSyncable } from '../helpers/async-storage-collection-syncable';
import { DefaultTag } from '../types/types';
import asyncStorageCollections from './async-storage-collections';

export const defaultUserWidgets = [
    { key: '0', label: WIDGETS.PINNED, isActive: true },
    { key: '1', label: WIDGETS.DUEDATE, isActive: true },
    { key: '2', label: WIDGETS.LATEST, isActive: true },
    { key: '3', label: WIDGETS.DATAPOINTS, isActive: true },
    { key: '4', label: WIDGETS.TAGS, isActive: true },
    { key: '5', label: WIDGETS.FILTER, isActive: true }
];

export const defaultUserData = {

    currency: 'EUR',
    docomondoId: 0,
    subscriptionPeriod: 0,
    subscriptionPeriodDocCount: 0,
    // mobile onboarding values:
    hasSeenEditMode: false, // from the old onboarding, still used to check if the user has seen the old onboarding -> then we dont show the new one
    hasSeenEditModeNew: false, // new userdata regarding the new tourguide tutorial
    hasSeenEditModeSuggestions: false,
    hasSeenHome: false,
    hasSeenHomeDataPoints: false,
    hasSeenHomeTags: false,
    hasSeenSearch: false,
    hasSeenSearchPart2: false,
    hasSeenSearchPart3a: false,
    hasSeenSearchPart3b: false,
    hasSeenSearchFilter: false,
    hasSeenSettings: false,
    // webapp onboarding values:
    hasSeenDashboardWeb: false,
    hasSeenFilterWeb: false,
    hasSeenSaveFilterWeb: false,
    hasSeenSettingsWeb: false,
    //ocrLanguage: getLanguageCode(),
    onboardingDone: false,
    optOutCloudReminder: false,
    reminderTime: 'morning',
    reviewAppDone: false,
    //reviewAppThreshold: APP_REVIEW_PROMPT_THRESHOLD,
    reviewAppNextTimestamp: 0,
    securityUseMetric: true,
    suggestions: true,
    suggestionsCount: 0,
    suggestionDocumentName: true,
    suggestionTags: true,
    suggestionIssuer: true,
    suggestionAmountCurrency: true,
    cloudProvider: null,
    sorted: null,
    widgets: defaultUserWidgets,
};

export const defaultCustomDocumentTypes: DefaultTag[] = [

    {
        key: 'WORK',
        i18nKey: 'document.type.work',
        serialized: 'WORK',
        enabled: true,
        count: 0,
    },
    {
        key: 'ID',
        i18nKey: 'document.type.ID',
        serialized: 'ID',
        enabled: true,
        count: 0,
    },
    {
        key: 'BANK',
        i18nKey: 'document.type.bank',
        serialized: 'BANK',
        enabled: true,
        count: 0,
    },
    {
        key: 'WARRANTY',
        i18nKey: 'document.type.warranty',
        serialized: 'WARRANTY',
        enabled: true,
        count: 0,
    },
    {
        key: 'TRAVEL',
        i18nKey: 'document.type.travel',
        serialized: 'TRAVEL',
        enabled: true,
        count: 0,
    },
    {
        key: 'RECIPE',
        i18nKey: 'document.type.recipe',
        serialized: 'RECIPE',
        enabled: true,
        count: 0,
    },
    {
        key: 'INSURANCE',
        i18nKey: 'document.type.insurance',
        serialized: 'INSURANCE',
        enabled: true,
        count: 0,
    },
    {
        key: 'LIVING',
        i18nKey: 'document.type.living',
        serialized: 'LIVING',
        enabled: true,
        count: 0,
    },
];

async function setUserData(value: any, key: string, collectionId: string, preventAMWSync = false) {

    const userDataCollection = asyncStorageCollections.collections[collectionId];

    if (preventAMWSync) {

        // dont always force the amw sync. e.g when iterating over the collection when initializing the userdata or coming from setUserAPIData()
        // this will lead to a lot of unnecessary requests on the backend
        return await userDataCollection.add(value, key, preventAMWSync);
    } else {

        return await userDataCollection.add(value, key, preventAMWSync);
    }
}

function getUserData(key: string, collectionId: string) {

    const userDataCollection = asyncStorageCollections.collections[collectionId] as AsyncStorageCollectionObjectSyncable;

    if (userDataCollection.collection[key]) {

        return userDataCollection.collection[key].value;
    }
}

export default {
    setUserData,
    getUserData,
};