import React from 'react';
import styles from '../../styles/BetaIndicator.module.scss';
import { useState } from 'react';
import { Popover, Badge, Text } from '@mantine/core';

export default function BetaIndicator () {

    const [opened, setOpened] = useState(false);

    return (
        <div className={styles.container}>
            <Popover
                opened={opened}
                onClose={() => setOpened(false)}
                position="bottom-end"
                withArrow
                trapFocus={false}
                closeOnEscape={false}
                transition="pop-top-left"
                width={260}
            >
                <Popover.Target>
                    <Badge variant="light" color="red" onMouseEnter={() => setOpened(true)} onMouseLeave={() => setOpened(false)}>
                        BETA
                    </Badge>
                </Popover.Target>
                <Popover.Dropdown>

                    <div style={{ display: 'flex' }}>
                        {/* <Image
                        src="./logo.svg"
                        width={30}
                        height={30}
                        sx={{ minWidth: 30 }}
                        mr="md"
                    /> */}
                        <Text size="sm">Web App still in beta...</Text>
                    </div>
                </Popover.Dropdown>
            </Popover>
        </div>
    );
}