import React, {useEffect} from 'react';
import { LoadingOverlay, MantineProvider } from '@mantine/core';
import
{ Analytics } from '@vercel/analytics/react';
import { Session } from 'next-auth';
import '../styles/globals.css';
import { SessionProvider } from 'next-auth/react';
import Script from 'next/script';
import type { AppProps } from 'next/app';
import { Toaster } from 'react-hot-toast';
import { pdfjs } from 'react-pdf';
import Head from 'next/head';
import asyncStorageCollections from '../services/async-storage-collections';
import { IntercomProvider } from 'react-use-intercom';
import BetaIndicator from '../components/betaIndicator/betaIndicator';
import { useAtom } from 'jotai';
import Atoms from '../context/Atoms';
import { appWithTranslation } from 'next-i18next';
import i18n from '../services/i18n';
import { useRouter } from 'next/router';
import { useStore } from '../context/AppStore';
// set pdf workersrc globally
// import pdf worker as a url, see `next.config.js` and `pdf-worker.js`
//import workerSrc from '../../pdf-worker';
//import url from 'pdfjs-dist/build/pdf.worker';
//pdfjs.GlobalWorkerOptions.workerSrc = url;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
function MyApp({ Component, pageProps:{ session, ...pageProps } }: AppProps<{ session: Session;}>) {

    const [isLoading,] = useAtom(Atoms.isLoadingAtom);
    const setPreviousRoute = useStore(state => state.setPreviousRoute);
    const setCurrentRoute = useStore(state => state.setCurrentRoute);
    const currentRoute = useStore(state => state.currentRoute);
    const { asPath } = useRouter();

    useEffect(() => {

        const init = async () => {

            i18n.init();
            asyncStorageCollections.init();
        };

        init();
    },[]);

    useEffect(() => {
        // store previousroute to handle router.back() in the app, e.g. when we're directly open a document via its cloudid inside the url, when we close the doc, back() would havigate outside the app, go to dashboard instead if we have no history/previous route
        setPreviousRoute(currentRoute ?? '/');
        // eslint-disable-next-line
        //@ts-ignore we know that asPath is a valid path
        setCurrentRoute(asPath);
    }, [asPath]);

    return (
        <>
            <Head>
                <link rel="shortcut icon" href="/favicon.ico" />
            </Head>
            <Script
                src="https://apis.google.com/js/client.js"
                strategy="lazyOnload"
            />
            <Script
                src="https://accounts.google.com/gsi/client"
                strategy="lazyOnload"
            />
            {/* <Script src="https://accounts.google.com/gsi/client" /> */}
            <SessionProvider session={session}>
                <MantineProvider
                    theme={{
                        components: {
                            Navbar: {
                            // Subscribe to theme and component params
                                styles: (theme) => ({
                                    root: {
                                        backgroundColor: theme.colorScheme === 'dark' ? 'blue' : theme.colors.gray[2],
                                    },
                                }),
                            },

                            Header: {
                            // Use raw styles object if you do not need theme dependency
                                styles: (theme)=> ({
                                    root: {
                                        backgroundColor: theme.colorScheme === 'dark' ? 'blue' : theme.colors.gray[2],
                                    },
                                }),
                            },
                        },
                        colors: {
                            'veridian': [
                                '#428177',
                                '#347D71',
                                '#277A6C',
                                '#1A7869',
                                '#0D7866',
                                '#007965',
                                '#0B6254',
                                '#125046',
                                '#16433B',
                                '#173833',
                            ],
                            'salmon': [
                                '#FD9074',
                                '#FD9074',
                                '#FD9074',
                                '#FD9074',
                                '#FD9074',
                                '#FD9074',
                                '#FD9074',
                                '#FD9074',
                                '#FD9074',
                                '#FD9074'
                            ],
                            'secondary': [
                                '#FD9074',
                                '#fd9b82',
                                '#fda690',
                                '#feb19e',
                                '#febcac',
                                '#fec8ba',
                                '#fed3c7',
                                '#feded5',
                                '#ffe9e3',
                                '#fff4f1'
                            ],
                        },
                        primaryColor: 'veridian',
                        fontFamily: 'Plain-Light, Greycliff CF, sans-serif',
                    }}
                >
                    <Toaster />
                    <IntercomProvider
                        appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID || ''}
                        autoBoot
                    >
                        <BetaIndicator />
                        <Analytics />
                        <Component {...pageProps} />
                        <LoadingOverlay style={{position: 'fixed'}}
                            visible={isLoading}
                        />
                    </IntercomProvider>
                </MantineProvider>
            </SessionProvider>
        </>
    );
}

export default appWithTranslation(MyApp);
