import {
    AUTH_API_URL
} from '../config';
import { DMDocument } from '../models/document';
import utilities from '../helpers/utilities';
import { DocumentDetails, FilterStates, MetaAll, SearchResult } from '../types/types';
import localizedURLs from '../services/localized-urls';
const baseHeaders = {

    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Accept-Language': 'en',
    'Authorization': 'Basic ' + 'docomondo:cRVY9h7tAHDz/svpRXF',
};
import gdrive from './gdrive';


interface AuthObject {
    email: string,
    password: string,
}

interface ExtendedAuthObject extends AuthObject {
    one_time_password: string,
}

const login = async ({ email, password, one_time_password }: ExtendedAuthObject) => {

    let data: AuthObject | ExtendedAuthObject;

    if (one_time_password) {
        data = {
            email,
            password,
            one_time_password,
        };
    } else {
        data = {
            email,
            password,
        };
    }

    const response = await fetch(`${AUTH_API_URL}/user/login`, {
        mode: 'cors',
        method: 'POST',
        headers: baseHeaders,
        body: JSON.stringify(data),
    });

    const responseJson = await response.json();

    if (response.status === 200) {

        return responseJson.additionalData.user;
    }

    if (response.status === 401) {

        return {

            oneTimePassword: true,
        };
    }

    return handleResponse(response, responseJson);
};

const register = async (email: string, password: string, name: string) => {

    const data = {
        // doc_id: currentSequenceNumber, // for guest account
        email,
        name,
        password,
    };
    const response = await fetch(`${AUTH_API_URL}/user/register`, {

        method: 'POST',
        headers: baseHeaders,
        body: JSON.stringify(data),
    });
    const responseJson = await response.json();

    if (response.status === 201) {

        // await sendReferralLink(getReferralLink());

        return true;
    }

    return handleResponse(response, responseJson);
};

const getAccessToken = async () => {

    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return [];
    }

    const response = await fetch(`${AUTH_API_URL}/user/gdrive/getAccessToken`, {

        mode: 'cors',
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + apiToken,
        },
    });

    if (response.status === 200) {

        const responseJson = await response.json();
        return responseJson.additionalData;
    }
};

const getDocumentByCloudId = async (cloudId: string) => {

    if (!cloudId) {
        return undefined;
    }
    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return undefined;
    }

    const response = await fetch(`${AUTH_API_URL}/document/get?cloudId=${cloudId}&api_token=${apiToken}`, {

        mode: 'cors',
        method: 'GET',
        headers: baseHeaders,
    });

    if (response.status === 200) {

        const doc = await response.json();

        const document = {
            cloudId: doc.additionalData.cloud_id as string,
            name: doc.additionalData.name as string,
            docomondoId: doc.additionalData.docomondoId as number,
            adapterPDFId: doc.additionalData.adapterPDFId as string,
            adapterRootFolderId: doc.additionalData.adapterRootFolderId as string,
            adapterThumbnailSmallId: doc.additionalData.adapterThumbnailSmallId as string,
            createdTime: doc.additionalData.docCreatedTime as string,
            meta: JSON.parse(doc.additionalData.meta),
            ocrText: doc.additionalData.ocr as string,
            dataPoints: JSON.parse(doc.additionalData.dataPointDetails),
            iconIndex: doc.additionalData.iconIndex as number,
        };

        return document;
    } else if (response.status === 400) {

        return null;
    }
    return undefined;
};

const storeDocument = async (document: DMDocument) => {

    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return;
    }

    const data: { meta: string } = {
        meta: document.toJSON(),
    };
    const response = await fetch(`${AUTH_API_URL}/document/store`, {

        mode: 'cors',
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + apiToken,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(data),
    });

    const responseJson = await response.json();
    if (response.status === 201) {

        return responseJson;
    }

    return null;
};

const getAllCloudIds = async () => {

    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return [];
    }

    const response = await fetch(`${AUTH_API_URL}/document/getAllCloudIds`, {

        method: 'GET',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + apiToken,
        },
    });

    const responseJson = await response.json();

    const cloudIds: string[] = [];
    for (const data of responseJson.additionalData) {
        cloudIds.push(data.cloud_id);
    }
    return cloudIds;
};

const getAllIssuers = async () => {

    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return [];
    }

    const response = await fetch(`${AUTH_API_URL}/document/getAllIssuers`, {

        method: 'GET',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + apiToken,
        },
    });

    const responseJson = await response.json();

    // comma separated string to array
    const issuers: string[] = responseJson.additionalData.split(',');
    return issuers;
};

const searchDocs = async (searchText: string, filterState: FilterStates): Promise<SearchResult[] | []> => {

    let payload = searchText !== '' ? `?q=${searchText}` : '';

    for (const [key, value] of Object.entries(filterState)) {

        let _value;
        if (value) {

            if (key === 'TYPE') {

                _value = Object.keys(value).join(',');
            } else if (key === 'ISSUE_DATE' || key === 'DUE_DATE') {

                const dateFrom: string | null = value.custom[0] ? new Date(value.custom[0]).toLocaleString() : null;
                const dateTo: string | null = value.custom[1] ? new Date(value.custom[1]).toLocaleString() : null;

                if (dateFrom && dateTo) {

                    _value = `${dateFrom}_${dateTo}`;
                } else if (dateFrom) {
                    _value = dateFrom;

                } else if (dateTo) {
                    _value = dateTo;
                } else {
                    continue;
                }
            } else if (key === 'AMOUNT_AND_CURRENCY') {
                const val = filterState[key];
                const content = [];
                for (const k in value) {
                    content.push(value[k][0]);
                    content.push(value[k][1]);
                }
                if (content) {

                    _value = `${content[0]}_${content[1]}`;
                }

            }
            if (payload === '') {

                payload = payload.concat(`?${key.toLowerCase()}=${_value ? _value : value}`);
            } else {

                payload = payload.concat(`&${key.toLowerCase()}=${_value ? _value : value}`);
            }
        }
    }



    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return [];
    }
    const response = await fetch(`${AUTH_API_URL}/document/search/${payload}`, {

        method: 'GET',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + apiToken,
        },
    });

    if (response.status === 200) {

        const responseJson = await response.json();
        if (responseJson.additionalData) {
            return responseJson.additionalData.map((el: any) => {
                return {
                    value: el.cloud_id,
                    label: el.name,
                };
            });
        }
    }

    return [];
};

const getDetails = async (cloudIds: string[]) => {

    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return false;
    }

    const data = {

        cloudIds,
    };

    const response = await fetch(`${AUTH_API_URL}/document/getDetails`, {

        method: 'POST',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + apiToken,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(data),
    });

    const responseJson = await response.json();

    if (response.status === 200) {

        return responseJson;
    }

    return handleResponse(response, responseJson);
};

const getMetaAll = async () => {

    // returns only the metadata from the backend which is newer than local. thats why we the id together with the timestamps with the request.
    // returns 304 if there are no new data on the backend

    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return [];
    }

    const response = await fetch(`${AUTH_API_URL}/user/meta/all`, {

        method: 'POST',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + apiToken,
        },
    });

    if (response.status === 404) {

        return undefined;
    }

    if (response.status === 304) {

        // return an empty array, 304 means that there is no more up do date data on the backend
        return [];
    }

    const responseJson = await response.json();
    if (response.status === 200) {

        return responseJson.additionalData as MetaAll ?? [];
    }

    return handleResponse(response, responseJson);
};

const getMeta = async (key: string, timestamp = 0) => {

    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return;
    }

    const response = await fetch(`${AUTH_API_URL}/user/meta/get/${key}`, {

        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + apiToken,
        },
    });
    if (response.status === 204) {

        // collection does not exist, create it
        return null;
    }

    if (response.status === 401) {

        return undefined;
    }

    if (response.status === 304) {

        return null;
    }

    const responseJson = await response.json();

    if (response.status === 200) {

        return responseJson.additionalData[key];
    }

    return handleResponse(response, responseJson);

};

const setMeta = async (key: string, value: string) => {

    //    const apiToken = await utilities.getAMWApiToken();
    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return;
    }

    const data = {

        key,
        value,
    };

    const response = await fetch(`${AUTH_API_URL}/user/meta/store`, {

        method: 'POST',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + apiToken,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Accept-Language': 'en',
        },
        body: JSON.stringify(data),
    });
    const responseJson = await response.json();

    if (response.status === 201) {

        return responseJson.additionalData;
    }

    return handleResponse(response, responseJson);
};

const getNextSequenceNumber = async (metaAllData: MetaAll) => {

    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return;
    }
    const response = await fetch(`${AUTH_API_URL}/user/doc-id?api_token=${apiToken}`, {

        method: 'GET',
        headers: baseHeaders,
    });
    const responseJson = await response.json();

    if (response.status === 200) {

        const sequenceNumber = responseJson.additionalData.doc_id;

        utilities.handleUserDataForNewDocument(sequenceNumber, metaAllData); // increments userdata like docomondoId, currentsubscription period and in case of a new subscurption period the current docId

        return sequenceNumber;
    }

    return handleResponse(response, responseJson);
};

const getUserData = async () => {

    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return [];
    }
    const response = await fetch(`${AUTH_API_URL}/user/status`, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + apiToken,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept-Language': 'de',
            Accept: 'application/json'
        }
    });

    const json = await response.json();
    return json.additionalData;
};

const addOrUpdateDocNotification = async (document_id: string, document_title: string, notify_at: string, notify_utc_offset: number) => {

    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return false;
    }

    const data = {
        document_id,
        document_title,
        notify_at,
        notify_utc_offset,
    };

    const response = await fetch(`${AUTH_API_URL}/user/doc-notification?api_token=${apiToken}`, {

        method: 'PUT',
        headers: baseHeaders,
        body: JSON.stringify(data),
    });

    if (response.status === 201) {

        return true;
    }

};

const deleteDocNotification = async (document_id: string) => {

    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return false;
    }

    const response = await fetch(`${AUTH_API_URL}/user/doc-notification/${document_id}?api_token=${apiToken}`, {

        method: 'DELETE',
        headers: baseHeaders,
    });
    const responseJson = await response.json();

    if (response.status === 200) {

        return true;
    }

    if (response.status === 410) {

        // notificatio not present in the backend!!!
        return null;
    }

    return handleResponse(response, responseJson);
};

const deleteAllData = async () => {

    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return false;
    }

    const files = await gdrive.getAllFileIds();
    const result = await gdrive.deleteAllData();
    if (result || files.length === 0) {

        const response = await fetch(`${AUTH_API_URL}/document/deleteAll`, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + apiToken,
            },
        });

        if (response.status === 200) {
            return true;
        }
    }

    return false;
};

const deleteDocumentByCloudId = async (cloudId: string) => {

    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return;
    }

    const data = {
        cloudId,
    };
    const response = await fetch(`${AUTH_API_URL}/document/deleteDocument`, {

        mode: 'cors',
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + apiToken,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 200 || response.status === 204) {

        return true;
    }

    return null;
};

const resendEmailVerification = async () => {

    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return;
    }

    const response = await fetch(`${AUTH_API_URL}/user/email/resend-verification?api_token=${apiToken}`, {

        method: 'POST',
        headers: baseHeaders,
    });
    const responseJson = await response.json();

    if (response.status === 200) {

        return true;
    }

    return handleResponse(response, responseJson);
};

const getLoginLink = async (target: string, locale: string) => {

    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return Promise.resolve(localizedURLs.getLocalizedURL('SETTINGS_URL', locale));
    }

    const data = {
        api_token: apiToken,
        target,
    };
    const response = await fetch(`${AUTH_API_URL}/user/login-link`, {

        method: 'POST',
        headers: baseHeaders,
        body: JSON.stringify(data),
    });
    const responseJson = await response.json();

    if (response.status === 200) {

        return responseJson.additionalData.link;
    }

    return handleResponse(response, responseJson);
};

const addLog = async (title: string, message: string) => {

    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return;
    }

    const data = {
        title,
        message,
    };
    const response = await fetch(`${AUTH_API_URL}/user/log/add`, {

        method: 'POST',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + apiToken,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 200) {

        return true;
    }

    return false;
};

const userDelete = async () => {


    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return;
    }

    const data = {
        api_token: apiToken,
        cancel_confirmed: true,
    };

    const response = await fetch(`${AUTH_API_URL}/user/delete`, {
        method: 'POST',
        headers: baseHeaders,
        body: JSON.stringify(data),
    });
    const responseJson = await response.json();

    if (response.status === 200) {

        return true;
    }

    return handleResponse(response, responseJson);
};

const processOcr = async (base64: string) => {

    const apiToken = await utilities.getAMWApiToken();
    if (!apiToken) {
        return;
    }

    const type = 'application/pdf';
    const base = base64.replace(`data:${type};base64,`, '');
    const body = JSON.stringify({
        priceId: 'plan_G3Ay3bXSRFRlUp',
        user_id: 'uuid',
        base64: base,
        type,
        //base64: base64Pages[0],
    });
    const result = await fetch(`${AUTH_API_URL}/user/ocr/init`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + apiToken,
        },
        body,
    });

    return result;
};

const handleResponse = (response: any, responseJson: any) => {

    switch (response.status) {

        // API token is missing or invalid
        case 304: {

            return responseJson;
        }

        // API token is missing or invalid
        case 401: {

            return responseJson;
        }

        // the used method is not allowed for the targeted endpoint
        case 405: {

            return responseJson;
        }

        // The target resource does not have a current representation that would be acceptable
        case 406: {

            return false;
        }

        // Unprocessable Entity - Some provided parameters could not be accepted by the application, e.g. the target parameter is missing or cannot be accepted
        case 422: {

            return responseJson;
        }

        // Too many requests - Will be returned after 5 failed login attempts
        case 429: {

            return {

                success: false,
                message: 'error.tooManyRequests',
            };
        }

        default: {

            return false;
        }
    }
};

export default {
    storeDocument,
    getAllCloudIds,
    getAllIssuers,
    searchDocs,
    login,
    getMeta,
    getMetaAll,
    getDocumentByCloudId,
    getDetails,
    setMeta,
    register,
    getAccessToken,
    getNextSequenceNumber,
    getUserData,
    addOrUpdateDocNotification,
    deleteDocNotification,
    deleteDocumentByCloudId,
    resendEmailVerification,
    getLoginLink,
    userDelete,
    addLog,
    processOcr,
    deleteAllData,
};