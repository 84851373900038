
import errorCodesAuthAPIInterface from '../pages/api/error-codes';

// services
import AsyncStorageCollectionArray from './async-storage-collection-array';
import AsyncStorageCollectionObject, {AsyncStorageCollectionObjectPlainItems, AsyncStorageCollectionObjectItems} from './async-storage-collection-object';
import asyncStorageCollections from '../services/async-storage-collections';

// helpers
import Syncable, {
    SyncablePlain,
} from './syncable';

export class AsyncStorageCollectionArraySyncable extends AsyncStorageCollectionArray {

    #syncIntervalId: number|undefined = undefined;

    constructor(id: string, isUnique = true, maxItems = -1) {

        super(id, isUnique, maxItems);
    }

    // async addAndSyncWithBackend(value: any): Promise<void> {

    //     await super.add(value);

    //     await this.forceSyncWithAMW();
    // }

    // async forceSyncWithAMW(): Promise<void> {

    //     // stop the sync interval as we will manually sync
    //     this.stopSyncInterval();
    //     await this.syncWithAMW();
    //     // restart the sync interval
    //     this.startSyncInterval();
    // }

    // startSyncInterval(): void {

    //     this.#syncIntervalId = setInterval(this.syncWithAMW, 60000);
    // }

    stopSyncInterval(): void {

        if (typeof this.#syncIntervalId !== 'undefined') {

            clearInterval(this.#syncIntervalId);
        }
    }

    getLastSyncedTimestamp(): number {

        const collectionSyncable = asyncStorageCollections.collections['syncables'].collection as AsyncStorageCollectionObjectItems;
        const lastSyncedAt: number = collectionSyncable[this.id]?.value as number;

        return lastSyncedAt ?? 0;
    }

    /**
   * sync this collection with data in AMW
   *
   * @param {Boolean} forceSync - if true forces the sync and does not check if
   * the collection is locked. forcing a sync may have unknown side effect and
   * may lead to data loss. only pass true if you're confident with what you are
   * doing here.
   */
    // syncWithAMW = async (forceSync = false): Promise<void> => {

    //     if (!forceSync && this.isLocked) {

    //         console.log(`AsyncStorageCollectionArraySyncable syncWithAMW(): collection ${this.id} is locked. skipping...`);

    //         return;
    //     }

    //     // get timestamp of when this collection was last synced with AMW
    //     const collectionSyncable = asyncStorageCollections.collections['syncables'].collection as AsyncStorageCollectionObjectItems;
    //     const lastSyncedAt: number = collectionSyncable[this.id]?.value as number;

    //     console.log(`AsyncStorageCollectionArraySyncable syncWithAMW(): collection ${this.id} last synced: ${lastSyncedAt}`);

    //     // check if the collection data is up-to-date
    //     const result = await authApiInterface.getMeta(this.id, lastSyncedAt)
    //         .catch(error => 41101);

    //     if (result === errorCodesAuthAPIInterface.ERROR_AUTH_API_GET_META) {

    //         return Promise.resolve();
    //     }

    //     if (result === undefined) {

    //         console.log(`AsyncStorageCollectionArraySyncable syncWithAMW(): no remote collection data for ${this.id} found. call setMeta`);

    //         // this collection has never been synced with AMW or
    //         // hasn't been modified since last sync

    //         await this.setMetaRemote();

    //         console.log(`AsyncStorageCollectionArraySyncable syncWithAMW(): done (no remote collection) for collection with id ${this.id}`);

    //         return;
    //     }

    //     if (result === null) {

    //         console.log(`AsyncStorageCollectionArraySyncable syncWithAMW(): no more up-to-date collection for ${this.id} found.`);

    //         // only send a more up-to-date collection to AMW if we have new data
    //         // else we would send the same collection again and again
    //         if (!this.isDirty) {
    //             console.log(`AsyncStorageCollectionArraySyncable syncWithAMW(): collection ${this.id} not flagged as dirty. Skip uploading the same data to AMW again.`);

    //             return;
    //         } else {
    //             console.log(`AsyncStorageCollectionArraySyncable syncWithAMW(): collection ${this.id} flagged as dirty. Uploading data to AMW again.`);
    //         }

    //         // send up-to-date version of the collection to AMW
    //         await this.setMetaRemote();

    //         console.log(`AsyncStorageCollectionArraySyncable syncWithAMW(): done (no more up-to-date found) for collection with id ${this.id}`);

    //         return;
    //     }

    //     console.log(`AsyncStorageCollectionArraySyncable syncWithAMW(): new data available for collection with id ${this.id}`);

    //     // there is new data available in AMW
    //     this.isLocked = true;

    //     // update last synced value for this collection
    //     //await asyncStorageCollections.collections['syncables'].add(new Date().getTime(), this.id);

    //     // iterate through all members of the collection
    //     // and merge the collection
    //     const collection = JSON.parse(result[this.id]);

    //     return this.mergeCollection(collection);
    // };

    // async mergeCollection(collection: Array<SyncablePlain>): Promise<void> {
    //     console.log(`AsyncStorageCollectionArraySyncable mergeCollection(): merging collection: ${this.id}`);

    //     const remoteSyncables = collection;
    //     const localSyncables: Array<Syncable> = this.collection;
    //     const processedUids = [];


    //     for (let i = 0; i < remoteSyncables.length; i++) {

    //         const remoteSyncable = remoteSyncables[i];

    //         // check if this syncable is already in the collection
    //         const localSyncable = localSyncables.find((localSyncable: Syncable) => localSyncable.uid === remoteSyncable.uid);

    //         processedUids.push(remoteSyncable.uid);

    //         if (localSyncable) {

    //             // syncable is already in the collection
    //             // update value if value from AMW is more up-to-date
    //             if (remoteSyncable.updatedTime > localSyncable.updatedTime) {

    //                 // AMWs syncable is more-up-to-date
    //                 // remote value -> local value
    //                 const {uid, value, createdTime, updatedTime} = remoteSyncable;

    //                 console.log(`AsyncStorageCollectionArraySyncable mergeCollection(): AMWs syncable is more-up-to-date. call setValues for a local syncable ${uid}`);

    //                 localSyncable.setValues(uid, '', value, createdTime, updatedTime);

    //             } else {

    //                 const {uid} = remoteSyncable;

    //                 console.log(`AsyncStorageCollectionArraySyncable mergeCollection(): AMWs syncable ${uid} is outdated. skipping...`);

    //                 // AMWs syncable is outdated
    //                 // local value -> remote value

    //                 // nothing to do. updating this collection in AMW
    //                 // will be done at the end of merging
    //             }

    //             continue;
    //         }

    //         // there is no local syncable for this uid found
    //         // in this collection
    //         // since we are updating the collection from a more
    //         // up-to-date version in AMW we just add the syncable
    //         // to our collection
    //         // or remove it depending if the user deleted the
    //         // syncable locally in between
    //         const deletedUidsCollection = asyncStorageCollections.collections['deletedUids'] as AsyncStorageCollectionObject;
    //         const {key, uid} = remoteSyncable;

    //         console.log(`AsyncStorageCollectionArraySyncable mergeCollection(): there is no local syncable for this uid found ${uid} ${key}`);

    //         if (deletedUidsCollection.collection[uid]) {

    //             console.log('AsyncStorageCollectionArraySyncable mergeCollection(): syncable deleted in between and is not subject to sync anymore');

    //             // syncable deleted in between and is not subject to sync anymore

    //             // cleanup local collection
    //             await deletedUidsCollection.remove(uid);

    //             continue;
    //         }

    //         console.log('AsyncStorageCollectionArraySyncable mergeCollection(): push syncable');

    //         const syncable = new Syncable();
    //         const {value, createdTime, updatedTime} = remoteSyncable;

    //         syncable.setValues(uid, '', value, createdTime, updatedTime);

    //         this.checkMaxItems();
    //         this.collection.push(syncable);

    //     }

    //     const _localSyncables: Array<Syncable> = this.collection.slice();

    //     // check if there are unprocessed items in the local collection
    //     for (let i = 0; i < _localSyncables.length; i++) {
    //         if (processedUids.includes(_localSyncables[i].uid)) {
    //             console.log(`AsyncStorageCollectionArraySyncable mergeCollection(): skip item since it is already processed ${i}`);

    //             continue;
    //         }

    //         if (_localSyncables[i].needsSync) {

    //             console.log(`AsyncStorageCollectionArraySyncable mergeCollection(): skip item since it marked as needsSync, needs to be uploaded to amw ${i}`);
    //             _localSyncables[i].needsSync = false;
    //             this.isDirty = true;
    //             continue;
    //         }

    //         console.log(`AsyncStorageCollectionArraySyncable mergeCollection(): remove item since it was never processed ${i}`);
    //         // remove the item since it is not part of the up-to-date collection in AMW
    //         await this.removeByValue(_localSyncables[i].value);
    //     }

    //     // local collection is merged and now represents the
    //     // most up-to-date collection

    //     // save local collection
    //     await this.save();

    //     if (this.isDirty) {

    //         // send up-to-date version of the collection to AMW
    //         await this.setMetaRemote();
    //     } else {

    //         await asyncStorageCollections.collections['syncables'].add(new Date().getTime(), this.id);
    //     }

    //     console.log(`AsyncStorageCollectionArraySyncable mergeCollection(): merging done for collection with id ${this.id}`);

    //     eventEmitterService.emit('onCollectionUpdate');
    // }

    // async setMetaRemote(): Promise<void> {

    //     const result = await authApiInterface.setMeta(this.id, JSON.stringify(this.asPlainArray));

    //     this.isDirty = false;

    //     if (!result) {

    //         return;
    //     }

    //     // update last synced value for this collection
    //     const lastSynced = new Date(result[this.id]).getTime();
    //     await asyncStorageCollections.collections['syncables'].add(lastSynced, this.id);
    // }

    // async clearRemoteData(): Promise<any> {

    //     return await authApiInterface.setMeta(this.id, JSON.stringify([]));
    // }
}

export class AsyncStorageCollectionObjectSyncable extends AsyncStorageCollectionObject {

    #syncIntervalId: number|undefined = undefined;

    constructor(id: string) {

        super(id);
    }

    // async addAndSyncWithBackend(value: any, key: string, isDefaultData?: boolean): Promise<void> {

    //     await super.add(value, key, isDefaultData);

    //     await this.forceSyncWithAMW();
    // }

    // async forceSyncWithAMW(): Promise<void> {

    //     // stop the sync interval as we will manually sync
    //     this.stopSyncInterval();
    //     await this.syncWithAMW();
    //     // restart the sync interval
    //     this.startSyncInterval();
    // }

    // startSyncInterval(): void {

    //     this.#syncIntervalId = setInterval(this.syncWithAMW, 60000);
    // }

    // stopSyncInterval(): void {

    //     if (typeof this.#syncIntervalId !== 'undefined') {

    //         clearInterval(this.#syncIntervalId);
    //     }
    // }

    getLastSyncedTimestamp(): number {

        const collectionSyncable = asyncStorageCollections.collections['syncables'].collection as AsyncStorageCollectionObjectItems;
        const lastSyncedAt: number = collectionSyncable[this.id]?.value as number;

        return lastSyncedAt ?? 0;
    }

    /**
   * sync this collection with data in AMW
   *
   * @param {Boolean} forceSync - if true forces the sync and does not check if
   * the collection is locked. forcing a sync may have unknown side effect and
   * may lead to data loss. only pass true if you're confident with what you are
   * doing here.
   */
    // syncWithAMW = async (forceSync = false): Promise<void> => {

    //     if (!forceSync && this.isLocked) {

    //         console.log(`AsyncStorageCollectionObjectSyncable syncWithAMW(): collection ${this.id} is locked. skipping...`);

    //         return;
    //     }

    //     // get timestamp of when this collection was last synced with AMW
    //     const collectionSyncable = asyncStorageCollections.collections['syncables'].collection as AsyncStorageCollectionObjectItems;
    //     const lastSyncedAt: number = collectionSyncable[this.id]?.value as number;

    //     console.log(`AsyncStorageCollectionObjectSyncable syncWithAMW(): collection ${this.id} last synced: ${lastSyncedAt}`);

    //     // check if the collection data is up-to-date
    //     const result = await authApiInterface.getMeta(this.id, lastSyncedAt)
    //         .catch(error => errorCodesAuthAPIInterface.ERROR_AUTH_API_GET_META);

    //     if (result === errorCodesAuthAPIInterface.ERROR_AUTH_API_GET_META) {

    //         return Promise.resolve();
    //     }

    //     if (result === undefined) {

    //         console.log(`AsyncStorageCollectionObjectSyncable syncWithAMW(): no remote collection data for ${this.id} found. call setMeta`);

    //         await this.setMetaRemote();

    //         console.log(`AsyncStorageCollectionObjectSyncable syncWithAMW(): done (no remote collection) for collection with id ${this.id}`);

    //         return;
    //     }

    //     if (result === null) {

    //         console.log(`AsyncStorageCollectionObjectSyncable syncWithAMW(): no more up-to-date collection for ${this.id} found.`);

    //         // only send a more up-to-date collection to AMW if we have new data
    //         // else we would send the same collection again and again
    //         if (!this.isDirty) {
    //             console.log(`AsyncStorageCollectionObjectSyncable syncWithAMW(): collection ${this.id} not flagged as dirty. Skip uploading the same data to AMW again.`);

    //             return;
    //         } else {
    //             console.log(`AsyncStorageCollectionObjectSyncable syncWithAMW(): collection ${this.id} flagged as dirty. Uploading data to AMW again.`);
    //         }

    //         await this.setMetaRemote();

    //         console.log(`AsyncStorageCollectionObjectSyncable syncWithAMW(): done (no more up-to-date found) for collection with id ${this.id}`);

    //         return;
    //     }

    //     console.log(`AsyncStorageCollectionObjectSyncable syncWithAMW(): new data available for collection with id ${this.id}`);

    //     // there is new data available in AMW
    //     this.isLocked = true;

    //     // update last synced value for this collection
    //     //await asyncStorageCollections.collections['syncables'].add(new Date().getTime(), this.id);

    //     // iterate through all members of the collection
    //     // and merge the collection
    //     const collection = JSON.parse(result[this.id]);

    //     return this.mergeCollection(collection);
    // };

    // async mergeCollection(remoteSyncables: AsyncStorageCollectionObjectPlainItems): Promise<void> {
    //     console.log(`AsyncStorageCollectionObjectSyncable mergeCollection(): merging collection: ${this.id}`);

    //     const processedUids = [];

    //     for (const remoteSyncableKey in remoteSyncables) {

    //         const remoteSyncable = remoteSyncables[remoteSyncableKey] as SyncablePlain;

    //         // check if this syncable is already in the collection
    //         const localSyncablesAsArray = this.asArray;
    //         const localSyncable = localSyncablesAsArray.find((localSyncable: Syncable) => localSyncable.uid === remoteSyncable.uid);

    //         processedUids.push(remoteSyncable.key);

    //         if (localSyncable) {

    //             // syncable is already in the collection
    //             // update value if value from AMW is more up-to-date
    //             if (remoteSyncable.updatedTime > localSyncable.updatedTime) {

    //                 // AMWs syncable is more-up-to-date
    //                 // remote value -> local value
    //                 const {uid, key, value, createdTime, updatedTime} = remoteSyncable;

    //                 console.log(`AsyncStorageCollectionObjectSyncable mergeCollection(): AMWs syncable is more-up-to-date. call setValues for a local syncable ${uid} ${key}`);

    //                 localSyncable.setValues(uid, key, value, createdTime, updatedTime);

    //             } else {

    //                 // AMWs syncable is outdated
    //                 // local value -> remote value

    //                 // nothing to do. updating this collection in AMW
    //                 // will be done at the end of merging
    //             }

    //             continue;
    //         }

    //         // there is no local syncable for this uid found
    //         // in this collection
    //         // since we are updating the collection from a more
    //         // up-to-date version in AMW we just add the syncable
    //         // to our collection
    //         // or remove it depending if the user deleted the
    //         // syncable locally in between
    //         const deletedUidsCollection = asyncStorageCollections.collections['deletedUids'] as AsyncStorageCollectionObject;
    //         const {key, uid} = remoteSyncable;

    //         console.log(`AsyncStorageCollectionObjectSyncable mergeCollection(): there is no local syncable for this uid found ${uid} ${key}`);

    //         if (deletedUidsCollection.collection[uid]) {

    //             console.log('AsyncStorageCollectionObjectSyncable mergeCollection(): syncable deleted in between and is not subject to sync anymore');
    //             // syncable deleted in between and is not subject to sync anymore

    //             // cleanup local collection
    //             await deletedUidsCollection.remove(uid);

    //             continue;
    //         }

    //         console.log('AsyncStorageCollectionObjectSyncable mergeCollection(): add syncable');

    //         const {value, createdTime, updatedTime} = remoteSyncable;
    //         const syncable = new Syncable();

    //         syncable.setValues(uid, key, value, createdTime, updatedTime);

    //         this.collection[key] = syncable;

    //     }

    //     // check if there are unprocessed items in the local collection
    //     for (const syncableKey in this.collection) {
    //         const uid = this.collection[syncableKey].key;

    //         if (processedUids.includes(uid)) {
    //             console.log(`AsyncStorageCollectionObjectSyncable mergeCollection(): skip item since it is already processed ${uid}`);

    //             continue;
    //         }

    //         if (this.collection[syncableKey].needsSync) {

    //             console.log(`AsyncStorageCollectionObjectSyncable mergeCollection(): skip item since it marked as needsSync, needs to be uploaded to amw ${uid}`);
    //             this.collection[syncableKey].needsSync = false;
    //             this.isDirty = true;
    //             continue;
    //         }

    //         console.log(`AsyncStorageCollectionObjectSyncable mergeCollection(): remove item since it was never processed ${uid}`);
    //         // remove the item since it is not part of the up-to-date collection in AMW
    //         await this.remove(syncableKey);
    //     }

    //     // local collection is merged and now represents the
    //     // most up-to-date collection

    //     // save local collection
    //     await this.save();

    //     if (this.isDirty) {

    //         await this.setMetaRemote();
    //     } else {

    //         await asyncStorageCollections.collections['syncables'].add(new Date().getTime(), this.id);
    //     }

    //     console.log(`AsyncStorageCollectionObjectSyncable mergeCollection(): merging done for collection with id ${this.id}`);

    //     eventEmitterService.emit('onCollectionUpdate');
    // }

    // async setMetaRemote(): Promise<void> {

    //     // send up-to-date version of the collection to AMW
    //     const result = await authApiInterface.setMeta(this.id, JSON.stringify(this.asPlainObject));

    //     this.isDirty = false;

    //     if (!result) {

    //         return;
    //     }
    //     const lastSynced = new Date(result[this.id]).getTime();
    //     // update last synced value for this collection
    //     await asyncStorageCollections.collections['syncables'].add(lastSynced, this.id);
    // }

    // async clearRemoteData(): Promise<any> {

    //     return await authApiInterface.setMeta(this.id, JSON.stringify({}));
    // }
}
