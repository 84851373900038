import create from 'zustand';
import { BulkDocument, dpKey, FilterStates, valueType } from '../types/types';
import type { Route } from 'nextjs-routes';

type FilterDetails = {
    key: string,
    name: string,
    searchText: string,
}

type UploadDetails = {

    docCount: number,
    uploaded: number,
    failed: number,
}

export type PDFAppState = {
    isPdf: boolean,
    pdf: File,
    imagesBase64: string[],
    images: Blob[],
    base64Pdf: string,
}

interface AppStore {
    filterState: FilterStates,
    filterDetails: FilterDetails,
    uploadDetails: UploadDetails,
    documentDataPoints: BulkDocument[],
    previousRoute: Route | '/',
    currentRoute: Route | '/',
    pdfState: PDFAppState | null,
    setPdfState: (value: PDFAppState) => void,
    setPreviousRoute: (value: Route | '/') => void,
    setCurrentRoute: (value: Route | '/') => void,
    setDocumentDataPoints: (value: BulkDocument[]) => void,
    setUploadDetails: (key: keyof UploadDetails, count: number) => void,
    setActiveFilterKey: (key: string) => void,
    setActiveFilterName: (key: string) => void,
    setActiveFilterSearchText: (key: string) => void,
    setFilterStateByKey: (key: dpKey, value: valueType) => void,
    setFilterState: (state: FilterStates) => void,
    removeFilterStateByKey: (key: dpKey) => void,
    clearFilterState: () => void,
}

const filterByKey = (state: FilterStates, key: dpKey) => {
    delete state[key];
    return state;
};

const setFilterDetails = (state: FilterDetails, key: string, value: string) => {

    state[key] = value;
    return state;
};

export const useStore = create<AppStore>()((set) => ({
    filterState: {
    },
    filterDetails: {
        key: '',
        name: '',
        searchText: '',
    },
    uploadDetails: {
        docCount: 0,
        uploaded: 0,
        failed: 0,
    },
    documentDataPoints: [],
    previousRoute: '/',
    currentRoute: '/',
    pdfState: null,
    setPdfState: (value: PDFAppState) => set({ pdfState: value }),
    setPreviousRoute: (value: Route | '/') => set({ previousRoute: value }),
    setCurrentRoute: (value: Route | '/') => set({ currentRoute: value }),
    setDocumentDataPoints: (value) =>
        set(() => ({
            documentDataPoints: [
                ...value,
            ],
        })),
    setUploadDetails: (key, value) =>
        set((state) => ({
            uploadDetails: {
                ...state.uploadDetails,
                [key]: value,
            },
        })),
    setActiveFilterKey: (key: string) =>
        set((state) => ({
            filterDetails: setFilterDetails(state.filterDetails, 'key', key),
        })),
    setActiveFilterName: (name: string) =>
        set((state) => ({
            filterDetails: setFilterDetails(state.filterDetails, 'name', name),
        })),
    setActiveFilterSearchText: (searchText: string) =>
        set((state) => ({
            filterDetails: setFilterDetails(state.filterDetails, 'searchText', searchText),
        })),
    removeFilterStateByKey: (key: dpKey) =>
        set((state) => ({
            filterState: filterByKey(state.filterState, key),
        })),
    setFilterStateByKey: (key, value) =>
        set((state) => ({
            filterState: {
                ...state.filterState,
                [key]: value,
            },
        })),
    setFilterState: (state: FilterStates) => set(() => ({ filterState: state })),
    clearFilterState: () => set(() => ({ filterState: {}, filterDetails: { key: '', name: '', searchText: '' } })),
}));