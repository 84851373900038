
const getSubscriber = async (userId: string): Promise<any> => {

    const res = await fetch(`https://api.revenuecat.com/v1/subscribers/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer strp_tAkfbOZmMjNJEeZEOXUHfklZVjg'
        }

    });
    const responseJSON = await res.json();
    return responseJSON;
};

const isSubscriptionActive = async (userId: string): Promise<boolean> => {

    const {subscriber} = await getSubscriber(userId);

    if (subscriber?.entitlements && subscriber.entitlements['premium']) {
        const premiumEntitlement = subscriber.entitlements['premium'];

        if (new Date(premiumEntitlement.expires_date) <= new Date(Date.now())) {

            return false;

        } else {

            return true;
        }
    } else {

        return false;
    }
};

const getOfferings = async (userId: string) => {

    const res = await fetch(`https://api.revenuecat.com/v1/subscribers/${userId}/offerings`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
            'X-Platform': 'web',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.NEXT_PUBLIC_REVENUECAT_STRIPE_APP_PUBLIC_KEY}`
        }

    });
    const responseJSON = await res.json();
    return responseJSON;
};

const isUserEligibleForTrial = async (userId: string) => {

    const result = await getSubscriber(userId);

    if (result.subscriber.subscriptions && Object.keys(result.subscriber.subscriptions).length > 0) {

        return false;
    } else {

        return true;
    }
};

export default {
    getSubscriber,
    isSubscriptionActive,
    getOfferings,
    isUserEligibleForTrial,
};