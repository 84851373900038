import { DOMAIN_URL } from '../config';

const URLS = {

    de: {

        DASHBOARD_URL: DOMAIN_URL + '/home',
        FORGOT_CREDENTIALS_URL: DOMAIN_URL + '/password/reset',
        HOME_URL: 'https://info.docomondo.com',
        PRIVACY_POLICY_URL: 'https://info.docomondo.com/datenschutz-app',
        SETTINGS_URL: DOMAIN_URL + '/settings',
        SHARE_URL: process.env.NODE_ENV !== 'production' ? 'https://share-development.docomondo.com' : 'https://share.docomondo.com',
        SUBSCRIPTION_URL: DOMAIN_URL + '/subscription',
        TERMS_AND_CONDITION_URL: 'https://info.docomondo.com',
        TIPS_URL: 'https://info.docomondo.com/tipps',
        DOCOMONDO_URL: 'https://docomondo.com',
    },
    en: {

        DASHBOARD_URL: DOMAIN_URL + '/home',
        FORGOT_CREDENTIALS_URL: DOMAIN_URL + '/password/reset',
        HOME_URL: 'https://info.docomondo.com',
        PRIVACY_POLICY_URL: 'https://legal.docomondo.com/app-privacy',
        SETTINGS_URL: DOMAIN_URL + '/settings',
        SHARE_URL: process.env.NODE_ENV !== 'production' ? 'https://share-development.docomondo.com/en' : 'https://share.docomondo.com/en',
        SUBSCRIPTION_URL: DOMAIN_URL + '/subscription',
        TERMS_AND_CONDITION_URL: 'https://legal.docomondo.com',
        TIPS_URL: 'https://legal.docomondo.com/tips',
        DOCOMONDO_URL: 'https://en.docomondo.com',
    }
};

function getLocalizedURL(key: string, locale: string) {

    if (!URLS[locale]) {
        locale = 'en';
    }

    return URLS[locale][key];
}

export default {

    getLocalizedURL,
};
