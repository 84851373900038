import { CustomTag, DMDataPointMeta, MetaAll, OcrProvider, OCRResponse, OCRWord, OCRWordsObj, Page, Pages, Tag, UserMeta, UserModel, VisionKitLine, VisionKitPage, VisionKitPages, Widgets, Word } from '../types/types';
import { SyncablePlain } from './syncable';
import { getSession } from 'next-auth/react';
import { defaultCustomDocumentTypes } from '../services/user-data';
import authApiInterface from '../services/auth-api-interface';
import AsyncStorageCollectionObject from './async-storage-collection-object';
import asyncStorageCollections from '../services/async-storage-collections';
import userDataService from '../services/user-data';
import revenueCat from '../services/revenue-cat';
import { BLACKLISTED_TAGS, FREE_TIER_DOCS_PER_MONTH, FREE_TIER_MAX_SUGGESTIONS, FREE_TIER_MAX_TAGS } from '../config';
import { DMDocument } from '../models/document';
import { format, differenceInCalendarDays, differenceInDays } from 'date-fns';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import gdrive from '../services/gdrive';
import Log from '../helpers/log';

const parseBoolean = (value: boolean | undefined | null | string): boolean => {

    if (value === undefined || value === null) {

        // Log.log(`Utilities parseBoolean(): value is ${value} returning false`);

        return false;
    }

    if (typeof value === 'boolean') {

        return value;
    }

    if (typeof value === 'string') {

        switch (value) {

            case 'true': return true;
            case 'false': return false;
            default: {

                // Log.log(`Utilities parseBoolean(): couldn't parse value: ${value} returning false as default`);

                return false;
            }
        }
    }

    // fallback e.g. object
    if (value) {

        return true;
    }

    return false;
};

const getBase64 = (file: File | Blob): Promise<string> => {
    return new Promise((resolve, reject) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            if (reader.result) {

                resolve(reader.result as string);
            } else {
                reject();
            }
        };
        reader.onerror = function (error) {
            reject(error);
        };
    });
};

const getWidgetCollections = async (result: any): Promise<Widgets> => {

    let latestUploads: string[] = [];
    let pinnedDocuments: string[] = [];
    let dueDates: string[] = [];
    let documentsWithoutDatapoint: string[] = [];

    for (const [key, value] of Object.entries(result)) {
        // value type unknown to string
        const valueString = value as string;
        switch (key) {
            case 'latestUploads':
                latestUploads = JSON.parse(valueString).map((e: SyncablePlain) => e.value).reverse() ?? [];
                break;
            case 'pinnedDocuments':

                pinnedDocuments = JSON.parse(valueString).map((e: SyncablePlain) => e.value) ?? [];
                break;
            case 'documentsWithoutDataPoint':
                {
                    const parsed = JSON.parse(valueString);
                    const docsWithoutDataPointsMap = new Map(Object.entries(parsed));
                    const keys = [...docsWithoutDataPointsMap.keys()];
                    documentsWithoutDatapoint = keys; // [...test].map((val) => val[0]) ?? [];
                }
                break;
            case 'dueDates':
                {
                    const parsed = JSON.parse(valueString);
                    const dueDatesMap = new Map<string, SyncablePlain>(Object.entries(parsed));
                    const dueDatesArray = Array.from(dueDatesMap.values()).map((a, b) => {
                        const now = new Date();
                        const dueDate = new Date(a.value);
                        const difference = differenceInDays(dueDate, now);
                        return {
                            cloudId: a.key,
                            date: difference,
                        };
                    });
                    dueDates = dueDatesArray.sort((a, b) => a.date - b.date).map(e => e.cloudId);
                }
                break;
            default:
                break;
        }
    }

    return {
        latestUploads,
        pinnedDocuments,
        dueDates,
        documentsWithoutDatapoint,
    };
};

const getAMWApiToken = async () => {
    const session = await getSession();

    if (session && session.user && session.user.api_token) {
        return session.user.api_token;
    }

    return null;
};


const isOCRResponse = (response: unknown): response is OCRResponse => {

    return (
        typeof response === 'object' &&
        typeof (response as OCRResponse).status === 'string' &&
        typeof (response as OCRResponse).createdDateTime === 'string' &&
        typeof (response as OCRResponse).lastUpdatedDateTime === 'string' &&
        typeof (response as OCRResponse).lastUpdatedDateTime === 'string' &&
        typeof (response as OCRResponse).analyzeResult === 'object' &&
        typeof (response as OCRResponse).analyzeResult.readResults === 'object' &&
        typeof (response as OCRResponse).analyzeResult.readResults[0].lines === 'object' &&
        typeof (response as OCRResponse).analyzeResult.readResults[0].width === 'number' &&
        typeof (response as OCRResponse).analyzeResult.readResults[0].height === 'number'
    );
};

const getOCRDetailsAzure = (oCRJSONParsed: unknown) => {

    const ocrTextArray: string[] = [];
    const wordsPerPage: OCRWordsObj[] = [];

    if (!isOCRResponse(oCRJSONParsed)) {

        return { ocrTextArray: null, ocrDetailsArray: null };
    }

    oCRJSONParsed.analyzeResult.readResults.forEach(page => {

        const words: OCRWord[] = [];
        const width = page.width;
        const height = page.height;

        page.lines.forEach(line => {

            ocrTextArray.push(line.text);

            line.words.forEach(w => {

                const word = {
                    text: w.text.toLowerCase(),
                    boundingBox: {
                        x: (100 / width * w.boundingBox[0]) / 100,
                        y: (100 / height * w.boundingBox[1]) / 100,
                        width: (100 / width * (Math.abs(w.boundingBox[2] - w.boundingBox[0])) / 100),
                        height: (100 / height * (Math.abs(w.boundingBox[7] - w.boundingBox[1])) / 100),
                    }
                };
                words.push(word);
            });
        });
        wordsPerPage.push({ words });
    });

    return { ocrTextArray, ocrDetailsArray: wordsPerPage };
};

// const getUserTags = async (limit?: number) => {
//     let tags:Tag[] = [];
//     const customTags = await authApiInterface.getMeta('customDMDocumentTypes');

//     if (customTags) {
//         const parsedTags = JSON.parse(customTags);
//         for (const cTag in parsedTags) {

//             if (parsedTags[cTag].value.enabled) {
//                 const displayName = parsedTags[cTag].value?.asString ?? i18next.t(parsedTags[cTag].value?.i18nKey);
//                 tags.push({key: cTag, name: displayName, count: parsedTags[cTag].value.count});
//             }
//         }
//     }

//     let dTags = defaultCustomDocumentTypes.map(tag => {

//         return({key: tag.key, name: i18next.t(tag.i18nKey), count: tag.count});
//     });
//     dTags = dTags.filter(el => !tags.some(el2 => el2.key === el.key));
//     tags.push(...dTags);

//     if (limit && tags.length > limit) {

//         tags = tags.sort((a, b) =>(b.count ?? 0) -( a.count ?? 0)).slice(0, limit);
//     }
//     return tags.sort((a,b) => {
//         return a.name.localeCompare(b.name);})
//         .map(el=> { return {key: el.key, name: el.name, count: el.count};});
// };

const getUserTags = async (data: string, limit?: number) => {
    let tags: {
        key: string,
        name: string,
        count: number,
        isI18n: boolean,
    }[] = [];

    if (data) {
        const parsedTags = JSON.parse(data);
        for (const cTag in parsedTags) {

            if (parsedTags[cTag].value.enabled) {
                const isI18n = parsedTags[cTag].value?.i18nKey !== undefined;
                const name = parsedTags[cTag].value?.asString ?? parsedTags[cTag].value?.i18nKey;
                tags.push({ key: cTag, name, isI18n, count: parsedTags[cTag].value.count });
            }
        }
    }

    let dTags = defaultCustomDocumentTypes.map(tag => {

        return ({ key: tag.key, isI18n: true, name: tag.i18nKey, count: tag.count });
    });
    dTags = dTags.filter(el => !tags.some(el2 => el2.key === el.key));
    tags.push(...dTags);

    if (limit && tags.length > limit) {

        tags = tags.sort((a, b) => (b.count ?? 0) - (a.count ?? 0)).slice(0, limit);
    }
    return tags;
};

const getTagsByKeys = async (data: string, keys: string[]) => {

    if (!data) {
        return [];
    }
    const parsedTags = JSON.parse(data);
    const resultTags: {
        key: string,
        name: string,
        isI18n: boolean,
        count: number,
        isActive: boolean,
    }[] = [];
    keys.forEach(key => {
        const _key = key.toUpperCase();
        if (parsedTags[_key.toUpperCase()]) {

            const isI18n = parsedTags[_key].value?.i18nKey !== undefined;
            const name = parsedTags[_key].value.asString ?? parsedTags[_key].value.i18nKey;
            resultTags.push({
                key: _key,
                name,
                isI18n,
                count: parsedTags[_key].value.count,
                isActive: true,
            });
        } else {

            const defaultTag = defaultCustomDocumentTypes.find(el => el.key === key);
            if (defaultTag) {
                resultTags.push({
                    key: key,
                    name: defaultTag.i18nKey,
                    isI18n: true,
                    count: defaultTag.count,
                    isActive: true,
                });
            }
        }
    });

    return resultTags;
};

const updateTagUsage = async (previousTags: string[], newTags: string[]) => {

    const customTags = await authApiInterface.getMeta('customDMDocumentTypes');

    if (customTags) {

        const parsedTags = JSON.parse(customTags);
        const changedTags: { key: string, value: any }[] = [];
        const removedTags = previousTags.filter((el: string) => !newTags.includes(el));
        const addedTags = newTags.filter((el: string) => !previousTags.includes(el));

        removedTags.forEach((tag: string) => {

            parsedTags[tag].value.count = parsedTags[tag].value.count - 1;
            changedTags.push({ key: tag, value: parsedTags[tag].value });
        });

        addedTags.forEach((tag: string) => {

            parsedTags[tag].value.count = parsedTags[tag].value.count + 1;
            changedTags.push({ key: tag, value: parsedTags[tag].value });
        });

        const collection = asyncStorageCollections.collections['customDMDocumentTypes'] as AsyncStorageCollectionObject;

        collection?.addBulk(changedTags, true);

    } else {

        //should not happen
        console.error('no tags found');
    }
};

const getNewSubscriptionPeriod = (userDataMeta: UserMeta) => {
    // returns if a new month has begun since the last doc upload. otherwise returns null
    const subscriptionPeriod = userDataMeta.subscriptionPeriod.value;
    const date = new Date();
    const currentPeriod = date.getFullYear() * 100 + date.getMonth();
    if (currentPeriod > subscriptionPeriod) {

        // new subscritpion period -> reset doc count for free tier
        return currentPeriod;

    } else {

        return null;
    }
};

const isFreeTierValid = async (userData: UserModel, metaAllData: MetaAll, fileCount: number) => {

    const userDataMeta = JSON.parse(metaAllData['userData']) as UserMeta;
    const subscriptionPeriod = getNewSubscriptionPeriod(userDataMeta);

    if (subscriptionPeriod) {

        // new subscritpion period -> free tier is valid,
        return true;
    } else {

        // check doc count of current subscription period
        const docCount = (await authApiInterface.getAllCloudIds()).length;
        const docCountSubscriptionPeriod = userDataMeta.subscriptionPeriodDocCount.value;
        return (docCount - docCountSubscriptionPeriod + fileCount) < FREE_TIER_DOCS_PER_MONTH;
    }
};

const handleUserDataForNewDocument = async (sequenceNumber: number, metaAllData: MetaAll) => {

    const userDataMeta = JSON.parse(metaAllData['userData']);
    const subscriptionPeriod = getNewSubscriptionPeriod(userDataMeta);

    // returns a subscription period if it is a new month, otherwise null is returned
    if (subscriptionPeriod) {

        // new subscritpion period -> reset doc count for free tier
        await userDataService.setUserData(subscriptionPeriod, 'subscriptionPeriod', 'userData');
        await userDataService.setUserData(sequenceNumber - 1, 'subscriptionPeriodDocCount', 'userData');
    }
};

const prepareOCRDetails = (ocrDetails: OCRWordsObj[]) => {

    const words: OCRWord[] = [];
    // deep clone it, otherwise we change the original reference and mess up word highlighting as the y coordinates will be out of bound for pages but the first
    const _ocrDetails = JSON.parse(JSON.stringify(ocrDetails)) as OCRWordsObj[];
    _ocrDetails.forEach((page, i) => {
        const _words = page.words ?? page;
        _words.forEach((word) => {

            const _word = word;
            _word.boundingBox.y = word.boundingBox.y + i;
            words.push(_word);
        });
    });

    return words;
};

const optimizeTextForSearch = (text: string) => {
    const result: string[] = [];
    const replaced = text.replace(/[\s\b\0&/\\#,+()[\]$~%.'":*?!<>{}]-/g, ' ');
    const words = replaced.split(' ');

    for (let i = 0; i < words.length; i++) {
        const word = words[i].trim();

        if (!result.includes(word) && word.length) {
            result.push(word);
        }
    }

    return result.join(' ');
};

const tryFindAll = (ocrText: string, words: string[]) => {

    const results: { name: string, count: number }[] = [];
    words.forEach(word => {
        const count = (ocrText.toLowerCase().match(new RegExp(word.toLowerCase(), 'g')) || []).length;
        if (count > 0) {
            const entry = {
                name: word,
                count,
            };
            results.push(entry);
        }
    });

    return results.sort((a, b) => b.count - a.count);
};

const getIssuers = (ocrText: string, metaAll: MetaAll) => {

    const userData = new Map(Object.entries(JSON.parse(metaAll['userData'])));

    if (!userData.has('issuers')) {

        return [];
    }

    const issuers = userData.get('issuers') as SyncablePlain;
    const results = tryFindAll(ocrText, issuers.value ?? []);

    return results;
};

const sortByYPosition = (tags: { name: string, count: number }[], ocrDetails: OCRWord[]) => {

    const tagNames = tags.map(tag => tag.name.toUpperCase());

    const words = [];
    for (let i = 0; i < ocrDetails.length; i++) {

        const word = ocrDetails[i];
        let tagName;
        const hasValue = tagNames.some(tag => {

            if (word.text.toUpperCase().includes(tag)) {

                tagName = tag;

                return true;
            } else {
                return false;
            }
        });

        if (hasValue) {

            words.push(
                {
                    name: tagName,
                    boundingBox: word.boundingBox,
                }
            );
        }
    }

    const sortedWords = words.sort(function (a, b) {

        return a.boundingBox.y - b.boundingBox.y;
    });

    const sortedTags = sortedWords.map(word => {
        return {
            name: word.name,
            count: tags[0].count,
        };
    });

    return sortedTags;
};

const sortMostUsedTagsByPosition = (results: { name: string, count: number }[], ocrDetails: OCRWord[]) => {

    let resultsSorted = [];

    if (results.length <= 2) {
        // no need to sort something as we only have 0 - 2 result
        return results;

    } else {
        // get all counts of tags
        const counts = results.map(el => el.count);
        // get unique counts (remove duplications);
        const uniqueCounts = [... new Set(counts)];
        // get all tags with max number of count
        const tagsMaxCount = results.filter(el => el.count === uniqueCounts[0]); 0;

        if (tagsMaxCount.length === 0) {
            // something went wrong!
            // return initial array
            return results;
        }

        if (tagsMaxCount.length > 1) {

            // return 2 tags sorted by position
            // get sortedbyposition
            // return 2
            const sortedResult = sortByYPosition(tagsMaxCount, ocrDetails);
            resultsSorted = [sortedResult[0], sortedResult[1]];
            resultsSorted = [...resultsSorted, ...results];
        } else {

            resultsSorted.push(tagsMaxCount[0]);


            if (uniqueCounts.length > 1) {

                const tagsSecondHighestCount = results.filter(el => el.count === uniqueCounts[1]);
                // return tags sorted by position
                // get sortedbyposition
                const sortedResult = sortByYPosition(tagsSecondHighestCount, ocrDetails);
                resultsSorted.push(sortedResult[0]);
            }
            // check second max value
            resultsSorted = [...resultsSorted, ...results];
        }

        return resultsSorted;
    }
};

const getDocumentTag = (ocrText: string, ocrDetails: OCRWord[], metaAll: MetaAll, customDMDocumentTypes: Tag[], locale: string) => {

    const tagNames: string[] = customDMDocumentTypes.map(t => t.name);

    const filteredTags = tagNames.filter((tag) => {
        return !BLACKLISTED_TAGS[locale].includes(tag.toUpperCase());
    });

    const _nameResults = tryFindAll(ocrText, filteredTags);
    const nameResults = sortMostUsedTagsByPosition(_nameResults, ocrDetails);

    // from the found i18n tag names, we need to return their KEY
    const keyResults: { key: string, name: string, count: number }[] = [];

    nameResults.forEach(el => {

        if (!el?.name) {
            return;
        }
        const found = customDMDocumentTypes.find(tag => tag.name.toUpperCase() === el.name.toUpperCase());
        if (!found) {
            return;
        }
        const result = {
            key: found?.key,
            name: el.name,
            count: el.count,
        };

        keyResults.push(result);
    });

    return keyResults;
};

const getCurrency = (ocrText: string) => {

    type currency = {
        name: string,
        amount: number
    }
    const chf: currency = {
        name: 'CHF',
        amount: ocrText.split(/CHF|Fr\.|Fr\n/).length - 1
    };
    const usd: currency = {
        name: 'USD',
        amount: ocrText.split(/USD|\$/).length - 1

    };
    const eur: currency = {
        name: 'EUR',
        amount: ocrText.split(/EUR|€/).length - 1
    };
    const currencies: currency[] = [chf, eur, usd];

    currencies.sort(function (a, b) {
        return b.amount - a.amount;
    });

    return currencies;
};

const getSortedAmounts = (results: string[], ocrDetailsWords: OCRWord[]) => {

    const filtered = ocrDetailsWords.filter((el) => {

        const _text = el.text.replace(/[$\-€£]/g, ''); // get rid of unnecessary characters

        return results.includes(_text);
    });

    const sorted = filtered.sort(function (a, b) {

        return b.boundingBox.y - a.boundingBox.y;
    });
    const sortedAmounts = sorted.map(el => {


        return el.text.replace(/[$\-€£']/g, '').replace(/,/g, '.');
    });

    return sortedAmounts;
};

const getMaxAmount = (results: string[]) => {

    const sorted = results.sort(function (a, b) {
        return parseFloat(b) - parseFloat(a);
    });
    const max = sorted;//sorted.slice(0, Math.min(3, sorted.length));

    return max.map(el => {
        const number = parseFloat(el);
        return (number / 100).toFixed(2);
    });
};

const getMergedAmount = (lastItems: string[], maxItems: string[]) => {

    const maxLen = Math.max(lastItems.length, maxItems.length);
    const merged = [];
    for (let i = 0; i < maxLen; i++) {
        if (maxItems[i]) { merged.push(maxItems[i]); }
        if (lastItems[i]) { merged.push(lastItems[i]); }
    }

    const uniqueMerged = [...new Set(merged)];

    return uniqueMerged.slice(0, 2);
};

const getDataPointSuggestionsFromOCR = async (ocrTextOptimized: string, ocrText: string, ocrDetails: OCRWordsObj[], userId: string, metaAll: MetaAll, tags: Tag[], locale: string) => {

    const user = JSON.parse(metaAll['userData']);
    const hasSuggestionsActive = user.suggestions.value;
    const hasActiveSubscription = await revenueCat.isSubscriptionActive(userId);
    const suggestionsCount = user.suggestionsCount.value;
    const isAllowedToUseSuggestions = hasActiveSubscription || suggestionsCount < FREE_TIER_MAX_SUGGESTIONS;
    const suggestedDataPoints = new Map<string, any>();
    if (hasSuggestionsActive && isAllowedToUseSuggestions) {

        // const hasDocumentNameSuggestionActive = userDataService.getUserData('suggestionDocumentName', 'userData');
        const hasTagSuggestionActive = user.suggestionTags.value;
        const hasIssuerSuggestionActive = user.suggestionIssuer.value;
        const hasAmountCurrencySuggestionActive = user.suggestionAmountCurrency.value;
        const ocrDetailsWords = prepareOCRDetails(ocrDetails);
        const documentTags = hasTagSuggestionActive ? getDocumentTag(ocrTextOptimized, ocrDetailsWords, metaAll, tags, locale) : [];
        if (documentTags.length > 0) {

            // todo , set active
            suggestedDataPoints.set('TYPE', documentTags.map(e => {
                const _t: Tag = {
                    key: e.key,
                    name: e.name,
                    isActive: true,
                };
                return _t;
            }).slice(0, 2));
        }
        const issuers = hasIssuerSuggestionActive ? getIssuers(ocrText, metaAll) : [];
        if (issuers.length > 0) {
            suggestedDataPoints.set('ISSUER', issuers.map(e => e.name).slice(0, 2));
        }

        let currencies = [];
        let lastItems = [];
        let maxAmount = [];

        //const keywords = ['Rechnung', 'Auftrag', 'Quittung'];
        // const hasKeyword = true; //keywords.some((el) => ocrTextOptimized.includes(el));

        // if (!hasKeyword) {

        //   // return as it is neither an invoice nor a bill.
        //   return;
        // }

        if (hasAmountCurrencySuggestionActive) {

            const regExpr = /(((\s|[$€])[1-9][0-9']*(\.|,)+([0-9]{2}))(?!(\.|,|\d)))|((\s(([1-9][0-9]?){1,3}(,{1}\d{3})*)\.{1}\d{2})(?!\.|\d))/g;

            const regExprResult = ocrTextOptimized.match(regExpr);
            if (regExprResult) {

                currencies = getCurrency(ocrText);
                const result = regExprResult.map(el => el.replace(/\s/g, ''));
                const result2 = regExprResult.map(el => el.replace(/\s?'?\$?€?£?,?\.?/g, ''));

                lastItems = getSortedAmounts(result, ocrDetailsWords);
                maxAmount = getMaxAmount(result2);
                const amount = getMergedAmount(lastItems, maxAmount);
                suggestedDataPoints.set('AMOUNT_AND_CURRENCY', [
                    {
                        key: 'AMOUNT',
                        value: amount,
                    },
                    {
                        key: 'CURRENCY',
                        value: currencies[0]?.name,
                    }
                ]);
            }
        }
    }

    return suggestedDataPoints;
};


const addCustomTag = async (tagName: string, tagsData: string, isSubscriptionActive: boolean, mutate?: () => void) => {

    const parsedTags = JSON.parse(tagsData);
    let customTagCount = 0;

    // count all custom tags
    Object.values(parsedTags).forEach((tag: any) => {
        if (tag.value?.asString !== undefined) {
            customTagCount++;
        }
    });

    if (isSubscriptionActive || !parsedTags || customTagCount < FREE_TIER_MAX_TAGS) {

        // check if tag exists

        // create new tag to backend
        const customType: CustomTag = {

            key: tagName.toUpperCase(),
            serialized: tagName.toUpperCase(),
            asString: tagName,
            enabled: true,
            count: 0,
        };

        await asyncStorageCollections.collections['customDMDocumentTypes']?.add(customType, customType.key);
        if (mutate) {
            mutate();
        }
        const tag = { key: customType.key, name: customType.asString, count: customType.count, isActive: true };
        return tag;
    } else {
        return null;
    }
};

const getDueDateInfoModifier = (document: DMDocument) => {

    try {

        const dataPointInstance = document.getDataPointByKey('DUE_DATE');
        let dateDue;
        if (!dataPointInstance) {
            // fallback: get de date from the dueDate collection
            const dueDatecollection = asyncStorageCollections.collections['dueDates'].collection;
            dateDue = new Date(dueDatecollection[document.cloudId].value);
        } else {

            dateDue = new Date(dataPointInstance.value);
        }

        if (dateDue) {
            const now = new Date();
            // Use differenceInCalendarDays to ignore the time to calculate the days.
            const days = differenceInCalendarDays(dateDue, now);

            let dueDateInfoModifier = 'future';

            if (days < 0) {

                dueDateInfoModifier = 'past';

            } else if (days === 0) {

                dueDateInfoModifier = 'present';

            } else if (days === 1) {

                dueDateInfoModifier = 'future_tomorrow';
            }

            return { dueDateInfoModifier, days };

        } else {

            // todo ?
            // console.log('TODO: Sync document datapoint before removing the document from collection, it might has been added on another device');

            // used to consider only the datapont from the document. If it was not found we removed the document from the duedate4 collection
            // removeDocumentFromDueDateCollection(cloudId);
            // problem was that if another device added the dueDate, the second device found it in the collection after sync, but not on the document yet.
            // so device B removed the due date from the collection again.
            // workaround was to fallback to the collection date.

            return null;

        }
    } catch (error) {

        console.error(error);
    }
};

const getOCRDetails = (ocrType: OcrProvider, oCRJSONParsed: unknown) => {

    if (ocrType === 'ScanbotSDK' || ocrType === 'MLKit' || ocrType === 'Cognitive Service') {

        return getOCRDetailsScanbot(oCRJSONParsed);

    } else if (ocrType === 'Vision/VNRecognizeTextRequest') {

        const { ocrDetailsArray } = getOCRDetailsVisionkit(oCRJSONParsed);
        return ocrDetailsArray;
    } else {
        return [];
    }
};

const getOCRDetailsScanbot = (oCRJSONParsed: unknown) => {

    const _oCRJSONParsed = oCRJSONParsed as Pages;
    return _oCRJSONParsed.pages.map((page: Page) => page.words);
};

const getOCRDetailsVisionkit = (oCRJSONParsed: unknown) => {

    const _oCRJSONParsed = oCRJSONParsed as VisionKitPages;
    const ocrTextArray: string[] = [];
    const ocrDetailsArray: (Word[])[] = [];

    _oCRJSONParsed.pages.forEach((page: VisionKitPage) => {

        const ocrDetails: Word[] = page.map((line: VisionKitLine) => {

            ocrTextArray.push(line.text);

            return {
                text: line.text,
                boundingBox: {
                    x: line.x,
                    y: line.y,
                    width: line.width,
                    height: line.height,
                }
            };
        });

        ocrDetailsArray.push(ocrDetails);
    });

    return { ocrTextArray, ocrDetailsArray };
};

type ExportDocs = {
    name: string,
    date: string,
    issuer: string,
    amount: number,
    currency: string,
}

const CURRENCY_FORMAT = {
    'CHF': '"CHF"#,##0.00',
    'EUR': '"€"#,##0.00',
    'USD': '"$"#,##0.00',
    'NONE': '#,##0.00',
};

const calcAndAddTotals = (documents: ExportDocs[], workSheet: Excel.Worksheet, currency: string, index: number): number => {

    const initialIndex = index;
    documents.forEach(d => {
        workSheet.addRow([
            format(new Date(d.date), 'dd-MM-yyyy'), d.name, d.issuer, d.amount
        ]);
        workSheet.getCell(`D${index++}`).numFmt = CURRENCY_FORMAT[currency];
    });

    const row2 = workSheet.addRow(['Total', '', '', { formula: `SUM(D${initialIndex}:D${documents.length + initialIndex - 1})` }]);
    workSheet.getCell(`D${index++}`).numFmt = CURRENCY_FORMAT[currency];
    row2.font = { bold: true };
    workSheet.addRow([]);

    return ++index;
};

const downloadReport = async (listName: string, selectedDocuments: string[], headers: string[]) => {

    try {
        const CURRENCIES = ['CHF', 'EUR', 'USD'];
        const documents: ExportDocs[] = [];
        for (const doc of selectedDocuments) {
            const docMeta = await authApiInterface.getDocumentByCloudId(doc);
            documents.push({
                name: docMeta?.name ?? '',
                date: docMeta?.createdTime ?? '',
                issuer: docMeta?.dataPoints.find((dp: DMDataPointMeta) => dp.key === 'ISSUER')?.value ?? '',
                amount: docMeta?.dataPoints.find((dp: DMDataPointMeta) => dp.key === 'AMOUNT_AND_CURRENCY')?.childs.find((dpChild: DMDataPointMeta) => dpChild.key === 'AMOUNT')?.value ?? 0,
                currency: docMeta?.dataPoints.find((dp: DMDataPointMeta) => dp.key === 'AMOUNT_AND_CURRENCY')?.childs.find((dpChild: DMDataPointMeta) => dpChild.key === 'CURRENCY')?.value ?? 'NONE',
            });
        }

        const file = await fetch('/api/getPublicImage?name=logo-with-text.png');
        if (file.status === 404) {

            return false;
        }
        const fileContents = await file.json();
        const wb = new Excel.Workbook();
        const ws = wb.addWorksheet();
        // add image to workbook by buffer
        const imageId2 = wb.addImage({
            base64: fileContents,
            extension: 'png',
        });
        // insert an image over A2:D4
        //ws.addImage(imageId2, 'A1:D3');
        // insert an image over part of B2:D6
        // aspect ratio is 3.9
        // 4 cells wide equals 212 px (53px/cell)
        // height needs to be 54.3 px
        // with 15px/cell this results in 3.6 cells
        ws.addImage(imageId2, {
            // types missing in library for this method, so we suppress it for now.
            // Type '{ col: number; row: number; }' is missing the following properties from type 'Anchor': nativeCol, nativeColOff, nativeRow, nativeRowOff, and 3 more.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-expect-error see above
            tl: { col: 0, row: 0 },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-expect-error see above
            br: { col: 4, row: 3.6 }
        });
        // add spacing at top for logo
        for (let i = 0; i < 5; i++) {
            ws.addRow([]);
        }
        const header = ws.addRow(headers);
        header.font = { bold: true };
        ws.getColumn('D').width = 10;
        // add datarows
        let index = 7; // offset of logo + header;
        index = calcAndAddTotals(documents.filter(d => d.currency === 'CHF'), ws, 'CHF', index);
        index = calcAndAddTotals(documents.filter(d => d.currency === 'EUR'), ws, 'EUR', index);
        index = calcAndAddTotals(documents.filter(d => d.currency === 'USD'), ws, 'USD', index);
        calcAndAddTotals(documents.filter(d => !CURRENCIES.includes(d.currency)), ws, 'NONE', index);

        // add image to workbook by filename

        // total row
        const buf = await wb.xlsx.writeBuffer();
        saveAs(new Blob([buf]), listName && listName !== '' ? `${listName}.xlsx` : 'docomondo.xlsx');
    } catch (error) {
        if (error instanceof Error) {

            Log.error(`export list failed:', ${error.message}`);
        } else {

            Log.error('export list failed: reason unknown');
        }
        return false;
    }
    return true;
};

const uploadDocument = async (document: DMDocument, pdf: File, thumbnail: Blob, metaAllData: MetaAll, hasDocomondoId = true): Promise<{ doc: DMDocument, error: boolean | null }> => {
    // check auth token and refresh if necessary
    await gdrive.getAuthToken();
    const parentRootfolderId = await gdrive.createFolder(document.cloudId);
    document.adapterRootFolderId = parentRootfolderId;
    const uploadPromises = [];
    const pages = { pages: document.ocrDetails };
    uploadPromises.push(gdrive.upload(JSON.stringify(pages), 'ocr.json', 'application/json', parentRootfolderId));
    uploadPromises.push(gdrive.upload(pdf, 'document.pdf', 'application/pdf', parentRootfolderId));
    uploadPromises.push(gdrive.upload(thumbnail, 'thumbnail-small.jpg', 'image/jpeg', parentRootfolderId));

    return Promise.all(uploadPromises)
        .then(async responses => {

            if (!document) {
                return { doc: document, error: true };
            }

            for (const response of responses) {

                if (!response.ok) {

                    throw new Error(`upload failed with status: ${response.status} and message: ${response.statusText}`);

                } else {

                    const json = await response.json();

                    if (json.name === 'meta.json') {

                        document.adapterId = json.id;
                    }

                    if (json.name === 'document.pdf') {

                        document.adapterPDFId = json.id;
                    }

                    if (json.name === 'thumbnail-small.jpg') {

                        document.adapterThumbnailSmallId = json.id;
                    }
                }
            }

            document.docomondoId = hasDocomondoId ? await authApiInterface.getNextSequenceNumber(metaAllData) : null;
            const fileContent = document.toJSON();
            // upload meta
            await gdrive.upload(fileContent, 'meta.json', 'application/json', parentRootfolderId);
            // upload meta.json to amw
            // todo add error handling!!!!
            await authApiInterface.storeDocument(document);
            return { doc: document, error: null };

        })
        .catch(err => {

            // todo cleanup docs that are already uploaded
            const message = JSON.stringify(err);
            Log.log(`document onSave err ${message}`, 'error');
            return { doc: document, error: true };
        });
};

const needsJoyride = (data: string, key: string) => {

    const parsedUserdata = JSON.parse(data);
    if (!parsedUserdata[key] || parsedUserdata[key]?.value === false) {
        return true;
    }
    return false;
};

export default {
    parseBoolean,
    getBase64,
    getWidgetCollections,
    getAMWApiToken,
    getOCRDetailsAzure,
    getUserTags,
    getTagsByKeys,
    updateTagUsage,
    isFreeTierValid,
    handleUserDataForNewDocument,
    optimizeTextForSearch,
    getDataPointSuggestionsFromOCR,
    addCustomTag,
    getDueDateInfoModifier,
    getOCRDetails,
    downloadReport,
    uploadDocument,
    needsJoyride,
};