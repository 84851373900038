import { atom } from 'jotai';

const isLoadingAtom = atom(false); // global loading overlay
const shouldPreventLeaving = atom(false); // global loading overlay
const openDataModal = atom(false); // navigation helper for settings screen (data modal)
const startWidgetJoyride = atom(false); // joyride/tutorial for vertical dots (more) on widget
const isFilterDrawerOpened = atom(false); // joyride/tutorial for vertical dots (more) on widget
const isTagNameInvalid = atom(false); // joyride/tutorial for vertical dots (more) on widget

export default {
    isLoadingAtom,
    shouldPreventLeaving,
    openDataModal,
    startWidgetJoyride,
    isFilterDrawerOpened,
    isTagNameInvalid,
};