//import analytics from '@react-native-firebase/analytics';
const env = process.env.NODE_ENV;
const IS_DEV_ENV = env === 'development';
if (env == 'development') {
    // do something
}
else if (env == 'production') {
    // do something
}

type Level = 'warning' | 'info' | 'error';

function error(error: Error | string) {

    if (IS_DEV_ENV) {

        console.error(error);

    } else {

        if (typeof error === 'string') {

            sentryCaptureMessage(error, 'error');
        } else {

            sentryCaptureMessage(error.message, 'error');
        }

    }
}

function sentryCaptureMessage(message: string, level: Level) {

    if (IS_DEV_ENV) {

        console.log('sentryCaptureMessage:', message);
        return;
    }

    // Sentry.captureMessage(message, level);
}

async function log(message: string, level: Level) {

    let env = '';

    switch (process.env.NEXT_PUBLIC_BASE_URL) {
        case 'http://localhost:3000':
            env = 'local';
            console.log(message);
            break;
        case 'https://docomondo-web-git-development-mwerder.vercel.app':
            env = 'development';

            break;
        case 'https://app.docomondo.com':
            env = 'production';

            break;
        default:
            env = 'unknown';
            break;
    }

    const entry = [];
    entry.push({
        env,
        type: level,
        message: `AxiomLog: ${message}`,
    });
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.NEXT_PUBLIC_AXIOM_TOKEN}`
        },
        body: JSON.stringify(entry)
    };

    fetch('https://cloud.axiom.co/api/v1/datasets/vercel/ingest', options)
        .then(response => response.json())
        .catch(err => console.error(err));
}

// function firebaseEvent(evt, params) {

//     if (IS_DEV_ENV) {

//         return;
//     }

//     if (params) {

//         analytics().logEvent(evt, params);

//     } else {

//         analytics().logEvent(evt);
//     }
// }

export default {

    //alert,
    error,
    log,
    sentryCaptureMessage,
    //firebaseEvent,
};
