export const DOMAIN_URL = process.env.NEXT_PUBLIC_AMW_URL;

export const AUTH_API_URL = DOMAIN_URL + '/api/v1';

export const WIDGETS = {
    PINNED: 'pinned',
    DUEDATE: 'duedate',
    LATEST: 'latest',
    DATAPOINTS: 'datapoints',
    TAGS: 'tags',
    FILTER: 'filter',
};

// minimum documents a user has to have until the app shows
// the subscription toasts on the home screen
export const FIRST_DOCUMENT = 1;
export const MIN_DOCUMENTS_SHOW_SUBSCRIPTION = 5;
export const MAX_DOCUMENTS_SHOW_SUBSCRIPTION = 11;
export const MAX_DOCUMENTS_FOR_GUEST = 3;
export const MAX_OCR_FAILURES_UNTIL_WARNING = 3;
export const FREE_TIER_DOCS_PER_MONTH = 15;
export const FREE_TIER_MAX_FILTERS = 5;
export const FREE_TIER_MAX_TAGS = 5;
export const FREE_TIER_MAX_SUGGESTIONS = 50;
export const TAG_NAME_MAX_LENGTH = 30;
// STRIPE
export const STRIPE_DEFAULT_PRICE_ID = 'price_1LnicfJOyCzgLBhfiw1yl6up';
export const TRIAL_DURATION = 30;
export const TRIAL_PERIOD = 'days';

export const BLACKLISTED_TAGS = {
    de: ['ID', 'AUSWEIS', 'PASS', 'REISEPASS', 'IDENTITÄTSKARTE'],
    en: ['ID', 'PASSPORT']
};