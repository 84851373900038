
import {v4 as uuidv4} from 'uuid';

export type SyncablePlain = {

    uid: string;
    createdTime: number;
    updatedTime: number;
    needsSync: boolean;
    key: string;
    value: any;
};

export default class Syncable {

    #uid = '';
    #createdTime = 0;
    #updatedTime = 0;
    #needsSync = false;
    #key = '';
    #value: any = undefined;

    get uid(): string {

        return this.#uid;
    }

    get createdTime(): number {

        return this.#createdTime;
    }

    get updatedTime(): number {

        return this.#updatedTime;
    }

    set updatedTime(value: number) {

        this.#updatedTime = value;
    }

    get needsSync(): boolean {

        return this.#needsSync;
    }

    set needsSync(value: boolean) {

        this.#needsSync = value;
    }

    get key(): string {

        return this.#key;
    }

    get value(): any {

        return this.#value;
    }

    set value(value: any) {

        this.#value = value;
    }

    initialize(key: string, value: any): void {

        this.#uid = uuidv4();
        this.#createdTime = new Date().getTime();
        this.#updatedTime = new Date().getTime();
        this.#needsSync = true;
        this.#key = key;
        this.#value = value;
    }
    setValues(uid: string, key: string, value: any, createdTime: number, updatedTime: number): void {

        this.#uid = uid;
        this.#createdTime = createdTime;
        this.#updatedTime = updatedTime;
        this.#key = key;
        this.#value = value;
    }

    getPlainObject(): SyncablePlain {

        return {

            uid: this.#uid,
            createdTime: this.#createdTime,
            updatedTime: this.#updatedTime,
            needsSync: this.#needsSync,
            key: this.#key,
            value: this.#value,
        };
    }
}
