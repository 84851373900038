import {
    AsyncStorageCollectionArraySyncable,
    AsyncStorageCollectionObjectSyncable,
} from '../helpers/async-storage-collection-syncable';
import authApiInterface from './auth-api-interface';
// import {doMigrate as doMigrateV1_16_0} from '@helpers/migrations/v1.16.0';
// import {doMigrate as doMigrateV1_17_0} from '@helpers/migrations/v1.17.0';

type AsyncStorageCollections = {

    [index: string]: AsyncStorageCollectionObjectSyncable|AsyncStorageCollectionArraySyncable;
};

const collections: AsyncStorageCollections = {};

// todo needed?
async function init(): Promise<void> {

    // synced with AMW
    collections['userData'] = new AsyncStorageCollectionObjectSyncable('userData');
    collections['documentsWithoutDataPoint'] = new AsyncStorageCollectionObjectSyncable('documentsWithoutDataPoint');
    collections['dueDates'] = new AsyncStorageCollectionObjectSyncable('dueDates');
    collections['latestSearches'] = new AsyncStorageCollectionArraySyncable('latestSearches', true, 3);
    collections['latestUploads'] = new AsyncStorageCollectionArraySyncable('latestUploads', true, 6);
    collections['pinnedDocuments'] = new AsyncStorageCollectionArraySyncable('pinnedDocuments', true);
    collections['topics'] = new AsyncStorageCollectionObjectSyncable('topics');
    collections['customDMDocumentTypes'] = new AsyncStorageCollectionObjectSyncable('customDMDocumentTypes');
    collections['dismissedTeasers'] = new AsyncStorageCollectionArraySyncable('dismissedTeasers');
    collections['earnedAwardsAndLevels'] = new AsyncStorageCollectionArraySyncable('earnedAwardsAndLevels');

    const collectionKeys: string[] = [
        'userData',
        'documentsWithoutDataPoint',
        'dueDates',
        'latestSearches',
        'latestUploads',
        'pinnedDocuments',
        'topics',
        'customDMDocumentTypes',
        'dismissedTeasers',
        'earnedAwardsAndLevels',
    ];

    const timestampCollection = collectionKeys.map(id => {

        return {id, timestamp: 0};
    });

    //const metaData = await authApiInterface.getMetaAll();

    // if (metaData) {

    //     const amwKeys = Object.keys(metaData).filter(el => collectionKeys.includes(el));

    //     if (amwKeys.length > 0) {

    //         for (const [key, value] of Object.entries(metaData)) {

    //             await collections[key].mergeCollection(JSON.parse(value));
    //         }
    //     }
    //     // syncNeeded = collectionKeys.filter(key => !amwKeys.includes(key));
    //     // for (const key of syncNeeded) {

    //     //   await collections[key].setMetaRemote();
    //     // }
    // }

    // await collections['userData'].init();
    // await collections['documentsWithoutDataPoint'].init();
    // await collections['dueDates'].init();
    // await collections['latestSearches'].init();
    // await collections['latestUploads'].init();
    // await collections['pinnedDocuments'].init();
    // await collections['topics'].init();
    // await collections['customDMDocumentTypes'].init();
    // await collections['dismissedTeasers'].init();
    // await collections['earnedAwardsAndLevels'].init();

    // // not synced with AMW
    // collections['syncables'] = new AsyncStorageCollectionObjectSyncable('syncables');
    // collections['userAPIData'] = new AsyncStorageCollectionObjectSyncable('userAPIData');
    // collections['userDataLocal'] = new AsyncStorageCollectionObjectSyncable('userDataLocal');
    // collections['deletedUids'] = new AsyncStorageCollectionObjectSyncable('deletedUids');

    // await collections['syncables'].init();
    // await collections['userAPIData'].init();
    // await collections['userDataLocal'].init();
    // await collections['deletedUids'].init();

    // __DEV__ @ts
    // await clearAMWData();
}

async function syncAll(): Promise<void> {

    // collections['userData'].startSyncInterval();
    // collections['documentsWithoutDataPoint'].startSyncInterval();
    // collections['dueDates'].startSyncInterval();
    // collections['latestSearches'].startSyncInterval();
    // collections['latestUploads'].startSyncInterval();
    // collections['pinnedDocuments'].startSyncInterval();
    // collections['topics'].startSyncInterval();
    // collections['customDMDocumentTypes'].startSyncInterval();
    // collections['dismissedTeasers'].startSyncInterval();
    // collections['earnedAwardsAndLevels'].startSyncInterval();
}

// async function cleanup(): Promise<void[]> {

//     collections['userData'].stopSyncInterval();
//     collections['documentsWithoutDataPoint'].stopSyncInterval();
//     collections['dueDates'].stopSyncInterval();
//     collections['latestSearches'].stopSyncInterval();
//     collections['latestUploads'].stopSyncInterval();
//     collections['pinnedDocuments'].stopSyncInterval();
//     collections['topics'].stopSyncInterval();
//     collections['customDMDocumentTypes'].stopSyncInterval();
//     collections['dismissedTeasers'].stopSyncInterval();
//     collections['earnedAwardsAndLevels'].stopSyncInterval();

//     const collectionsToClear = [

//         collections['userData'],
//         collections['documentsWithoutDataPoint'],
//         collections['dueDates'],
//         collections['latestSearches'],
//         collections['latestUploads'],
//         collections['pinnedDocuments'],
//         collections['topics'],
//         collections['syncables'],
//         collections['userAPIData'],
//         collections['userDataLocal'],
//         collections['customDMDocumentTypes'],
//         collections['dismissedTeasers'],
//         collections['earnedAwardsAndLevels'],
//         collections['deletedUids'],
//     ];

//     const promises = collectionsToClear.map(collectionToClear => {

//         return collectionToClear.clear();
//     });

//     return Promise.all(promises);
// }

// async function clearAMWData() {

//   Log.log('AsyncStorageCollections clearAMWData(): WARNING! you are calling a dev environment only test function. this should never happen in any other environment. Please check if this call is intended!')

//   await cleanup();

//   await collections['userData'].clearRemoteData();
//   await collections['documentsWithoutDataPoint'].clearRemoteData();
//   await collections['dueDates'].clearRemoteData();
//   await collections['latestSearches'].clearRemoteData();
//   await collections['latestUploads'].clearRemoteData();
//   await collections['pinnedDocuments'].clearRemoteData();
//   await collections['topics'].clearRemoteData();
// }

export default {

    collections,
    init,
    syncAll,
};
