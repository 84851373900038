
// services

// helpers
import Syncable, { SyncablePlain } from './syncable';
import authApiInterface from '../services/auth-api-interface';
import { getSession } from 'next-auth/react';

type Operation = {

    operation: string,
    params: Array<string | any>;
};

export type AsyncStorageCollectionObjectItems = {

    [index: string]: Syncable;
};

export type AsyncStorageCollectionObjectPlainItems = {

    [index: string]: any;
};

export default class AsyncStorageCollectionObject {

    /* id
   * a unique identifier for this collection.
   * identifier need to be unique across the
   * whole project since it is used to store
   * the collecion in AsyncStorage
   */
    id = '';

    /* isLocked
   * a collection is locked until it is synced with
   * corresponding data (if any) in AsyncStorage
   */
    isLocked = true;

    /* collection
   * the collection itself
   */
    collection: AsyncStorageCollectionObjectItems = {};

    /* queue
   * holds all queried operations added while the collection was locked
   */
    queue: Array<Operation> = [];

    /**
   * isDirty
   * each time the collection is modified the isDirty flag
   * is set to true
   */
    isDirty = true;

    constructor(id: string) {

        this.id = id;
    }

    get asArray(): Array<Syncable> {

        const array = [];

        for (const key in this.collection) {

            array.push(this.collection[key]);
        }

        return array;
    }

    get asPlainObject(): AsyncStorageCollectionObjectPlainItems {

        const data: AsyncStorageCollectionObjectPlainItems = {};

        for (const key in this.collection) {

            data[key] = this.collection[key].getPlainObject();
        }

        return data;
    }

    async getRemoteData(): Promise<AsyncStorageCollectionObjectItems> {

        const session = await getSession();
        if (session && session.user) {

            const response: null | undefined | string = await authApiInterface.getMeta(this.id);
            if (response === undefined || response === null) {

                return {};
            }

            const jsonParsed = JSON.parse(response) as AsyncStorageCollectionObjectItems;
            const data: AsyncStorageCollectionObjectItems = {};

            for (const key in jsonParsed) {

                const syncable = new Syncable();
                const parsedSyncable = jsonParsed[key];
                syncable.setValues(parsedSyncable.uid, parsedSyncable.key, parsedSyncable.value, parsedSyncable.updatedTime, parsedSyncable.createdTime);
                data[key] = syncable;
            }
            this.collection = data;

            return this.collection;
        }

        // todo error handling
        return this.collection;
    }

    async setRemoteData(): Promise<void> {

        const session = await getSession();
        if (session && session.user) {

            const res = await authApiInterface.setMeta(this.id, JSON.stringify(this.asPlainObject));
            // todo handle response
        } else {

            // todo error handling
        }
    }

    // async init(): Promise<AsyncStorageCollectionObject> {

    //     //return this.getLocalData();
    // }

    async addBulk(values: any[], isDefaultData?: boolean): Promise<void> {
        await this.getRemoteData();

        values.forEach((el, idx) => {

            const item = this.collection[el.key];

            if (!item) {

                // there is no previous item
                // create a new Syncable
                const syncable = new Syncable();
                syncable.initialize(el.key, el.value);

                // set the timestamp updatedAt to 0 (01.01.1970)
                // to prevent overwriting user preferred data in
                // AMW with default values set at a later time
                if (isDefaultData) {

                    syncable.updatedTime = 0;
                }

                this.collection[el.key] = syncable;

            } else {

                // update existing item
                item.updatedTime = new Date().getTime();
                item.value = el.value;
            }
        });
        return this.setRemoteData();
    }

    async add(value: any, key: string, isDefaultData?: boolean): Promise<void> {

        await this.getRemoteData();

        const item = this.collection[key];

        if (!item) {

            // there is no previous item
            // create a new Syncable
            const syncable = new Syncable();
            syncable.initialize(key, value);

            // set the timestamp updatedAt to 0 (01.01.1970)
            // to prevent overwriting user preferred data in
            // AMW with default values set at a later time
            if (isDefaultData) {

                syncable.updatedTime = 0;
            }

            this.collection[key] = syncable;

        } else {

            // update existing item
            item.updatedTime = new Date().getTime();
            item.value = value;
        }

        await this.setRemoteData();
    }

    async remove(key: string): Promise<string> {

        // get remote collection
        await this.getRemoteData();

        delete this.collection[key];

        await this.setRemoteData();

        return JSON.stringify(this.asPlainObject);
    }
}
