
// services

// helpers
import Syncable, {SyncablePlain} from './syncable';
import authApiInterface from '../services/auth-api-interface';
import { getSession } from 'next-auth/react';

type Operation = {

    operation: string,
    params: Array<string | any>;
};

export default class AsyncStorageCollectionArray {

    /* id
   * a unique identifier for this collection.
   * identifier need to be unique across the
   * whole project since it is used to store
   * the collecion in AsyncStorage
   */
    id = '';

    /* isLocked
   * a collection is locked until it is synced with
   * corresponding data (if any) in AsyncStorage
   */
    isLocked = false;

    /* isUnique
   * if true a collection item is unique and can't
   * be added a second time
   */
    #isUnique = false;

    /* maxItem
   * determines how much items are allowed in the
   * the collection. If an itam is added and the collection
   * exceeds maxItems the first item of the list is
   * deleted
   */
    #maxItems = -1;

    /* collection
   * the collection itself
   * @member
   * @type {Array}
   */
    collection: Array<Syncable> = [];

    /* queue
   * holds all queried operations added while the collection was locked
   * @member
   * @type {Array}
   */
    queue: Array<Operation> = [];

    /**
   * isDirty
   * each time the collection is modified the isDirty flag
   * is set to true
   */
    isDirty = true;

    constructor(id: string, isUnique = true, maxItems = -1) {

        this.id = id;
        this.#isUnique = isUnique;
        this.#maxItems = maxItems;
    }

    get asPlainArray(): Array<SyncablePlain> {

        return this.collection.map(syncable => syncable.getPlainObject());
    }

    async getRemoteData(): Promise<Array<Syncable>> {
        const session = await getSession();
        if (session && session.user) {

            const response: null|undefined|string = await authApiInterface.getMeta(this.id);
            if (response === undefined || response === null) {

                return [];
            }

            const json = JSON.parse(response) as Array<SyncablePlain>;
            const collection: Array<Syncable> = [];

            json.forEach((item: SyncablePlain) => {

                const {uid, value, createdTime, updatedTime} = item;
                const syncable = new Syncable();

                syncable.setValues(uid, '', value, createdTime, updatedTime);

                collection.push(syncable);
            });

            this.collection = collection;

            return this.collection;
        }

        // todo error handling
        return this.collection;
    }

    async setRemoteData(): Promise<void> {

        const session = await getSession();
        if (session && session.user) {

            await authApiInterface.setMeta(this.id, JSON.stringify(this.asPlainArray));
            // todo handle response
        } else {

            // todo error handling
        }
    }

    async add(value: any): Promise<void> {

        await this.getRemoteData();
        //this.collection = collection;

        if (!this.#isUnique) {

            this._addItem(value);

            return this.setRemoteData();
        }

        if (!this.hasItemWithValue(value)) {

            this._addItem(value);

            return this.setRemoteData();
        }
    }

    hasItemWithValue(value: any): boolean {

        return this.collection.some(item => item.value === value);
    }

    _addItem(value: any): void {

        this.checkMaxItems();

        const syncable = new Syncable();
        syncable.initialize('', value);

        this.collection.push(syncable);
        this.isDirty = true;
    }

    /**
   * remove the first item of the collection
   * if adding a new item would exceed maxItems
   */
    checkMaxItems(): void {

        if (this.#maxItems !== -1 && this.collection.length + 1 > this.#maxItems) {

            this.collection.shift();
        }
    }

    async removeByValue(value: any): Promise<void> {

        await this.getRemoteData();

        let promise = Promise.resolve();

        this.collection.forEach(item => {

            if (item.value === value) {

                this._remove(item);
                promise =  this.setRemoteData();
            }
        });

        return promise;
    }

    async _remove(item: Syncable): Promise<void> {

        const index = this.collection.indexOf(item);

        if (index !== -1) {

            this.collection.splice(index, 1);
            this.isDirty = true;
        }
    }
}
