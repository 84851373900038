import i18n from 'i18next';
// Translation files
import translation_de from '../public/locales/de/common.json';
import translation_en from '../public/locales/en/common.json';

const init = () => {

    i18n
        .init({
            // Load language resources
            compatibilityJSON: 'v3', // migration from i18n v20 to v21 changed the json format for plurals. somehow it does not work. use old json format.
            resources: {
                'de': {
                    translation: translation_de
                },
                'en': {
                    translation: translation_en
                }
            },
            fallbackLng: 'en',
            interpolation: {
                escapeValue: false
            }
        });

};

export default {
    init,
};