import toast from 'react-hot-toast';
import dummyDoc from '../public/docomondo-dummy-document.jpg';
import authApiInterface from './auth-api-interface';
import create from 'zustand/vanilla';
import Log from '../helpers/log';

const useStore = create(() => ({ gdt: '', gdt_exp: '' }));

const getAuthToken = async () => {

    const state = useStore.getState();
    const googleDriveToken = state.gdt;
    const googleDriveTokenExpiration = state.gdt_exp;

    // check if we have cached the auth token and check its expiration time
    // we only store the access token in memory so it should be ok. don't store it in local or sesion storage
    if (!googleDriveToken || !googleDriveTokenExpiration || new Date(googleDriveTokenExpiration) <= new Date(Date.now())) {

        // fetch new access token from AMW
        const response = await authApiInterface.getAccessToken();
        useStore.setState({ gdt: response.token });
        useStore.setState({ gdt_exp: (Date.now() + (response.expires_in * 1000)).toString() });

        return response.token;
    } else {

        return googleDriveToken;
    }
};

const getFileIdFromName = async (name: string, parentFolderId: string) => {

    const access_token = await getAuthToken();
    const res = await fetch(`https://www.googleapis.com/drive/v3/files?q="${parentFolderId}" in parents and name="${name}" and trashed=false`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`,
            //'Authorization' : 'Bearer ' + 'ya29.a0ARrdaM_o2aEkLCeHqnqt7XDBtNWHgq2aBIpx4JYqSpF5LOjeoUcb50N_3_DiTKlZiOlgb0nkukAsI7BOIlxWfOHaO1UN3lyP9-VucrCvtiR1LfyzPeNWkmHj5mNSC0qGUaSnyvFpM3eWm43llDTk5cb57klx'

            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
    const json = await res.json();
    if (json.files.length === 0) {
        return false;
    } else {

        return json.files[0].id;
    }
};

const fetchCloudIdsBatched = async (access_token: string, pageToken: string) => {

    const res = await fetch(`https://www.googleapis.com/drive/v3/files?q=name="meta.json" and trashed=false&pageSize=200&pageToken=${pageToken}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`,
        },
    });

    return res;
};

const getAllFileIds = async () => {

    const fileIds: string[] = [];

    const access_token = await getAuthToken();
    const res = await fetch('https://www.googleapis.com/drive/v3/files?q=name="meta.json" and trashed=false&pageSize=200', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`,
            //'Authorization' : 'Bearer ' + 'ya29.a0ARrdaM_o2aEkLCeHqnqt7XDBtNWHgq2aBIpx4JYqSpF5LOjeoUcb50N_3_DiTKlZiOlgb0nkukAsI7BOIlxWfOHaO1UN3lyP9-VucrCvtiR1LfyzPeNWkmHj5mNSC0qGUaSnyvFpM3eWm43llDTk5cb57klx'

            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
    });

    if (res.status === 200) {

        let resJSON = await res.json();

        resJSON.files.forEach((file: any) => fileIds.push(file.id));

        while (resJSON.nextPageToken) {

            const result = await fetchCloudIdsBatched(access_token, resJSON.nextPageToken);

            resJSON = await result.json();
            resJSON.files.forEach((file: any) => fileIds.push(file.id));
        }

        return fileIds;
    } else if (res.status === 401) {

        toast.error('Bummer! It seems you are not authenticated. \n Make sure you are signed in to your cloud provider');
        return [];
    } else {

        toast.error(`Bummer! something went wrong. \n Error: ${res.status}`);
        return [];
    }
};

const getDocs = async (fileIds: string[]) => {

    const docs = [];
    for (const id of fileIds) {

        const res = await fetch(`https://www.googleapis.com/drive/v3/files/${id}?alt=media`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + await getAuthToken()

                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        const resjson = await res.json();
        const parentfolder = resjson.adapterRootFolderId;
        // parentFolder = parentfolder;
        const name = resjson.name;
        const cloudId = resjson.cloudId;
        const resThumbnail = await fetch(`https://www.googleapis.com/drive/v3/files?q="${parentfolder}" in parents and name="thumbnail-small.jpg"`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + await getAuthToken()

                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        if (resThumbnail.status !== 200) {

            docs.push({ name, cloudId, webViewLink: dummyDoc.src });
        } else {

            const resThumbnailJson = await resThumbnail.json();

            const resPng = await fetch(`https://www.googleapis.com/drive/v3/files/${resThumbnailJson.files[0].id}?fields=thumbnailLink`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + await getAuthToken()

                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
            const resres = await resPng.json();
            docs.push({ name, cloudId, webViewLink: resres.thumbnailLink });
        }
    }

    return docs;
};

const getThumbnail = async (fileId: string) => {

    const response = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?fields=thumbnailLink`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + await getAuthToken()

            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
    });

    const json = await response.json();
    return json.thumbnailLink;
};

const getFolderAdapterId = async () => {

    const response = await fetch('https://www.googleapis.com/drive/v3/files?q=name="Docomondo" AND mimeType="application/vnd.google-apps.folder" AND trashed=false', {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + await getAuthToken(),
            'Content-Type': 'application/json'
        },
    });

    const responseJSON = await response.json();
    if (responseJSON?.files?.length > 0) {

        return responseJSON.files[0].id;
    }

    return null;
};

const createFolderAction = async (name: string, parent: string) => {

    const data = {
        name,
        mimeType: 'application/vnd.google-apps.folder',
        parents: [parent]
    };

    const response = await fetch('https://www.googleapis.com/drive/v3/files', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + await getAuthToken(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    if (response.status === 200) {

        const adapterId = (await response.json()).id;
        return adapterId;
    }
    return null;
};

const createFolder = async (name: string) => {

    // todo remember rootadapterid
    let rootfolderAdapterId = await getFolderAdapterId();

    if (!rootfolderAdapterId) {
        // create rootfolderAdapterId
        rootfolderAdapterId = await createFolderAction('Docomondo', 'root');
    }

    const response = createFolderAction(name, rootfolderAdapterId);
    return response;
};

const upload = async (fileContent: string | File | Blob, name: string, mimeType: string, parentFolderId: string) => {
    // Reference: https://stackoverflow.com/a/16245768
    const metadata = { name, parents: [parentFolderId] };
    const fd = new FormData();
    fd.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    fd.append('file', fileContent);

    let response = await fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
        method: 'POST',
        headers: { Authorization: 'Bearer ' + await getAuthToken() },
        body: fd
    });

    if (response.status === 401) {
        // auth failed, try to retry, maybe token expired and failed to refresh, try again
        response = await fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
            method: 'POST',
            headers: { Authorization: 'Bearer ' + await getAuthToken() },
            body: fd
        });
    }
    return response;
};

const update = async (fileContent: string, name: string, parentFolderId: string) => {

    const metadata = { name, addParents: [parentFolderId] };
    const fd = new FormData();
    fd.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    fd.append('file', fileContent);

    const responseFileIds = await fetch(`https://www.googleapis.com/drive/v3/files?q="${parentFolderId}" in parents and name="${name}" and trashed=false`, {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + await getAuthToken() },

    }).then((res) => res.json());

    if (!responseFileIds?.files?.length) {

        return null;
    }

    const updateResponse = await fetch(`https://www.googleapis.com/upload/drive/v3/files/${responseFileIds.files[0].id}?uploadType=multipart`, {
        method: 'PATCH',
        headers: { Authorization: 'Bearer ' + await getAuthToken() },
        body: fd
    });
    return updateResponse.status === 200;
};

const getOcrFileFromAdapterId = async (parentFolderId: string) => {

    const resOcr = await fetch(`https://www.googleapis.com/drive/v3/files?q="${parentFolderId}" in parents and name="ocr.json"`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + await getAuthToken()
        },
    });

    if (resOcr.status === 200) {

        const resOcrParsed = await resOcr.json();

        const response = await fetch(`https://www.googleapis.com/drive/v3/files/${resOcrParsed.files[0].id}?alt=media`, {
            method: 'GET',
            headers: { Authorization: 'Bearer ' + await getAuthToken() },
        });

        if (response.status === 200) {

            const res = await response.json();
            return res;
        }
        return response;
    }
};

const downloadPdf = async (fileId: string) => {
    const response = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + await getAuthToken() },
    });

    // the response is no json format. create a file from it (body is a ReadableStream)
    const blob = await response.blob();
    const file = new Blob(
        [blob],
        { type: 'application/pdf' });
    return file;
};

const download = async (fileId: string) => {
    //const response = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?fields=createdTime`, {
    const fileDownload = fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + await getAuthToken() }
    });

    const metaDownload = fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?fields=createdTime`, {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + await getAuthToken() },
    });

    return Promise.all([fileDownload, metaDownload]).then(async (responses) => {

        const json = await responses[0].json();
        const meta = await responses[1].json();
        json.createdTime = meta.createdTime;
        json.modifiedTime = meta.modifiedTime;
        return json;
    }).catch(error => {

        const message = JSON.stringify(error);
        Log.log(`error downloading gdrive file: ${message}`, 'error');
    });
};

const deleteAllData = async () => {

    const rootfolderAdapterId = await getFolderAdapterId();
    const result = await deleteRoot(rootfolderAdapterId);
    return result;
};

const deleteRoot = async (rootFolderId: string) => {

    const response = await fetch(`https://www.googleapis.com/drive/v3/files/${rootFolderId}`, {
        method: 'DELETE',
        headers: { Authorization: 'Bearer ' + await getAuthToken() },
    });

    if (response.status === 204) {

        return true;
    } else {
        return false;
    }
};

const info = async () => {

    const response = await fetch('https://www.googleapis.com/drive/v3/about?fields=user', {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + await getAuthToken() },
    });

    if (response.ok) {

        const json = await response.json();
        return json;
    } else {

        return null;
    }
};

const revoke = async () => {

    const access_token = await getAuthToken();
    try {

        google.accounts.oauth2.revoke(access_token, () => {
            //console.log('done');
        });
    } catch (error) {
        //
    }
};

export default {

    getAuthToken,
    getAllFileIds,
    getFileIdFromName,
    getDocs,
    getOcrFileFromAdapterId,
    getThumbnail,
    createFolder,
    upload,
    update,
    downloadPdf,
    download,
    deleteRoot,
    deleteAllData,
    info,
    revoke,
};